import React from "react";
import {
    Box,
    Flex,
    Text,
    Center,
    Image,
} from "@chakra-ui/react";

import { FaTelegramPlane } from "react-icons/fa";
import { RiLinkedinFill } from "react-icons/ri";
import { AiOutlineInstagram, AiOutlineYoutube } from "react-icons/ai";
import { RiFacebookFill } from "react-icons/ri";
import { FiGlobe } from "react-icons/fi";
import { RiWhatsappLine } from "react-icons/ri";
import { t } from "../../language"

import banner from '../../Assets/Images/Banner/bannerAnimate.gif'
import comunidade from '../../Assets/Images/Banner/Comunidade.png'
import { IconLinkToFolowSocialAccounts } from "../../Components/ButtonsComponents/iconLinkToFolowSocialAccounts";
import { useConfig } from "../../Hooks/ConfigProvider";

export const HeaderBannerTitleAndSocialLinks = () => {
    const { config } = useConfig();
    const SOCIALS = [
        {
            name: "Linkedin",
            icon: <RiLinkedinFill color="#454545" className="icon-social" size={"20px"} />,
            url: `${config.linkedin}`,
        },
        {
            name: "Instagram",
            icon: <AiOutlineInstagram color="#454545" size={"25px"} />,
            url: `${config.instagram_url}`,
        },
        {
            name: "Website",
            icon: <FiGlobe color="#454545" size={"25px"} />,
            url: `${config.website_url}`,
        },
        {
            name: "Facebook",
            icon: <RiFacebookFill color="#454545" size={"25px"} />,
            url: `${config.facebook_url}`,

        },
        {
            name: "Youtube",
            icon: <AiOutlineYoutube color="#454545" size={"25px"} />,
            url: `${config.youtube_url}`,
        },
        {
            name: "Whatsapp",
            icon: <RiWhatsappLine color="#454545" size={"25px"} />,
            url: `${config.whatsapp_url}`,
        },
        {
            name: "Telegram",
            icon: <FaTelegramPlane color="#454545" size={"25px"} />,
            url: `${config.telegram_url}`,
        },
    ];

    return (
        <>
            <Center >
                <Flex justifyContent='space-between'
                    w='100vw'
                    h={{base:'87vh',md:'93vh',xl:'98vh', '2xl':'90vh'}}
                    bgPosition="center"
                    bgImage={banner}
                    bgSize='cover'
                    flexWrap='wrap-reverse'
                >
                    <Box w={{ base: '350px', md: '513px' }}
                        mt={{base:'10vh',xl:'25vh', '2xl':'25vh'}}
                        mb={{base:'10vh',xl:'15vh', '2xl':'25vh'}}
                       ml= {{ base: '2vh',md: '10vh' }}
                    >
                        <Image src={comunidade} />
                        <Text w={{ base: '350px', md: '400px' }}
                            fontFamily='Montserrat'
                            fontStyle='normal'
                            fontWeight='700'
                            fontSize={{ base: '40px', md: '53px', lg: '53px', xl: '53px' }}
                            lineHeight='65px'
                            color='#FFFFFF'
                        >
                            {t('buddha_name')}
                        </Text>
                        <Box w={{ base: '350px', md: '363px', lg: '459px', xl: '513px', '2xl': '613px' }}
                            mt={{ base: '11px', md: '21px', xl: '6vh' }}
                            fontFamily='Montserrat'
                            fontStyle='normal'
                            fontWeight='300'
                            fontSize={{ base: '20px', md: '22px' }}
                            lineHeight='27px'
                            color='#FFFFFF'
                        >
                            <Text>{t('NFTs_Buddha')}</Text>
                            <Text>  {t('the_technological')}</Text>
                            <Text> {t('with_quantity')}</Text>
                            <Text> {t('our client')}</Text>
                        </Box>
                    </Box>
                        <Flex  gridGap={{ base: '10px', md: '15px' }}
                            p={{ base: '2vh',md: '10vh' }} >
                            {SOCIALS.map((social, index) => (
                                <IconLinkToFolowSocialAccounts
                                    key={index}
                                    url={social.url}
                                    icon={social.icon}
                                    name={social.name}
                                />
                            ))}
                        </Flex>
                </Flex>
            </Center>
        </>
    );
}

