import React from "react";
import {
    Button,
    Flex,
    FormControl,
    Box,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    useDisclosure,
    Text,
    Center,
    Radio,
    IconButton,
    Image,
    Grid,
    Link,
} from "@chakra-ui/react";

import { AiOutlineGift } from 'react-icons/ai';
import { IoIosArrowBack, IoMdList } from 'react-icons/io';
import { BsStar } from "react-icons/bs";

import Whatsapp from "../../Assets/Icons/whatsapp.svg";
import discont from "../../Assets/Icons/desconto.png";
import diamond from "../../Assets/Icons/diamond.png";
import ticket from "../../Assets/Icons/ticket.png";
import comunity from "../../Assets/Icons/comunity.png";
import { BenefitGift } from "../Benefit/benefitGift";
import {t} from '../../language'


type buttonRescueProps = {
    ml?: string
}
 export function ButtonRescueNFTHome({ ml }: buttonRescueProps) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const initialRef = React.useRef(null)
    const finalRef = React.useRef(null)
    const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure()
    const { isOpen: isOpen3, onOpen: onOpen3, onClose: onClose3 } = useDisclosure()

    const OverlayBur = () => (
        <ModalOverlay
            bg='none'
            backdropFilter='auto'
            backdropInvert='80%'
            backdropBlur='2px'
        />
    )

    const [overlay] = React.useState(<OverlayBur />)
    return (
        <>
            <Button w='263px'
                onClick={onOpen}
                ml={ml}
                h='60px'
                bg='#FFFFFF'
                boxShadow='0px 4px 19px rgba(0, 0, 0, 0.05)'
                borderRadius='47px'
                leftIcon={<AiOutlineGift color='#7E0000' size={'18px'} />}
                fontFamily='montserrat'
                fontStyle='normal'
                fontWeight='400'
                fontSize='18px'
                lineHeight='22px'
                _hover={{ bg: '#FFFFFF' }}

            >{t('rescue')}
            </Button>
            <Modal
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}
                isOpen={isOpen}
                onClose={onClose}
                isCentered
            >    {overlay}
                <ModalOverlay />
                <ModalContent w={{ base: '380px', md: '507px' }}
                    h='526'
                    mt={{ base: '50px', md: '100px', lg: '100px', xl: '150px' }}>
                    <Center w='100%'>
                        <Flex justifyContent='space-between'
                            w={{ base: '350px', md: '405px' }}
                            h='45px'
                            mt={{ base: '15px', md: '25px', lg: '25px', xl: '50px' }}>
                            <Text fontFamily='Montserrat'
                                fontStyle='normal'
                                fontWeight='700'
                                fontSize={{ base: '16px', md: '18', xl: '22px' }}
                                lineHeight='64px'
                                color='#454545'
                            >{t('rescue')}
                            </Text>
                            <ModalCloseButton w={{ base: '10px', md: '15px', xl: '25px' }}
                                fontSize='25px'
                                h={{ base: '10px', md: '15px', xl: '25px' }}
                                mt={{ base: '30px', md: '30px', lg: '35px', xl: '55px' }}
                                mr='25px'
                            />
                        </Flex>
                    </Center>
                    <Center w='100%'>
                        <Flex w={{ base: '350px', md: '405px' }} h='45px' mt='41px'>
                            <Text fontFamily='Montserrat'
                                fontStyle='normal'
                                fontWeight='500'
                                fontSize='16px'
                                lineHeight='19px'
                                color='#454545'
                            >
                                {t('rescue_description')}
                            </Text>
                        </Flex>
                    </Center>
                    <ModalBody pb={6}>
                        <FormControl mt='28px'>
                            <FormLabel> {t('voucher')}</FormLabel>
                            <Input h='60px' ref={initialRef} placeholder='' borderColor='#454545' />
                        </FormControl>
                        <Text mt='10px'
                            fontFamily='Montserrat'
                            fontStyle='normal'
                            fontWeight='400'
                            fontSize='16px'
                            lineHeight='19px'
                            color='#CCCCCCCC'
                        >{t('example')}
                        </Text>
                        <Box>
                            <Radio value='1' colorScheme='red'>
                                <Text mt='41px'
                                    fontFamily='Montserrat'
                                    fontStyle='normal'
                                    fontWeight='500'
                                    fontSize='16px'
                                    lineHeight='19px'
                                    color='#9E9E9E'
                                >{t('check')}
                                </Text>

                                <Button fontFamily='Montserrat'
                                    fontStyle='normal'
                                    fontWeight='700'
                                    fontSize='16px'
                                    lineHeight='19px'
                                    color='#454545'
                                    bg='0'
                                    _hover={{ bg: '0' }}
                                >{t('terms')}
                                </Button>
                            </Radio>
                        </Box>
                        <Center>
                            <Button mt={{ base: '15px', md: '15px', lg: 0 }}
                                w={{ base: '350px', md: '407px' }}
                                h='60px'
                                bg='#FFF'
                                borderRadius='48px'
                                border='2px solid #DFDFDF'
                                _hover={{ bg: '#FFFF' }}
                                _focus={{ bg: '#FFFF' }}
                                onClick={onOpen2}
                            >{t('validate_voucher')}
                            </Button>
                        </Center>
                    </ModalBody>
                </ModalContent>
            </Modal >
            {/*Modal você recebeu um presente */}
            <Modal closeOnOverlayClick={false} isOpen={isOpen2} onClose={onClose2} isCentered size={'728px'}>
                {overlay}
                <ModalOverlay />
                <ModalContent p='3vh' maxW={{ base: '550px', '2xl': '710px' }}>
                    <Center w='100%'>
                        <Text fontFamily='Montserrat'
                            fontStyle='normal'
                            fontWeight='700'
                            fontSize={{ base: '16px', md: '18', '2xl': '22px' }}
                            lineHeight='44px'
                            color='#7E0000'
                        >{t('gift')}
                        </Text>
                    </Center>
                    <ModalCloseButton display={{ base: 'none', md: 'block' }}
                        fontSize={{ base: '15px', '2xl': '25px' }}
                        m='2vh'
                    />
                    <Center >
                        <Text fontFamily='Montserrat'
                            fontStyle='normal'
                            fontWeight='400'
                            fontSize={{ base: '16px', '2xl': '18px' }}
                            lineHeight='19px'
                            color='#9E9E9E'
                            textAlign={{ base: 'center', md: 'center' }}
                        >{t('gift_text')}
                        </Text>
                    </Center>
                    <Center mt='4vh'>
                        <Box w={{ base: '180px', 'xl': '224px', '2xl': '354px' }}
                            h={{ base: '180px', 'xl': '224px', '2xl': '354px' }}
                            borderRadius='15px'
                            bg='#454545'
                        > </Box>
                    </Center>
                    <Center mt='2vh'>
                        <Text fontFamily='Montserrat'
                            fontStyle='normal'
                            fontWeight='700'
                            fontSize={{ base: '18px', '2xl': '22px' }}
                            lineHeight={{ base: '21px', '2xl': '26px' }}
                            color='#454545'
                        >{t('nft_name')}
                        </Text>
                    </Center>
                    <Center mt='2vh'>
                        <Text fontFamily='Montserrat'
                            fontStyle='normal'
                            fontWeight='700'
                            onClick={onOpen3}
                            fontSize={{ base: '16px', '2xl': '18px' }}
                            lineHeight={{ base: '18px', '2xl': '21px' }}
                            color='#7E0000'
                        >{t('details')}
                        </Text>
                    </Center>
                    <Center mt='3vh'>
                        <Box maxW='427px'  >
                            <Text fontFamily='Montserrat'
                                fontStyle='normal'
                                fontWeight='400'
                                fontSize={{ base: '18px', '2xl': '22px' }}
                                lineHeight={{ base: '21px', '2xl': '26px' }}
                                color='#353535'
                                textAlign='center'
                            >{t('description_nft')}
                            </Text>
                        </Box>
                    </Center>
                    <Flex flexWrap='wrap' mt='3vh'>
                        <Flex justifyContent='space-around' w='100%'>
                            <Button w={{ base: '145px', lg: '200px', '2xl': '300px' }}
                                h={{ base: '50px', '2xl': '60px' }}
                                bg='#7E0000'
                                borderRadius='45px'
                                fontFamily='Montserrat'
                                fontStyle='normal'
                                fontWeight='500'
                                fontSize={{ base: '18px', md: '22px' }}
                                lineHeight={{ base: '21px', md: '26px' }}
                                color='#FFFF'
                                _hover={{
                                    bg: '#7E0000',
                                    color: '#f5f5f5'
                                }}
                                _focus={{ bg: '#7E0000' }}
                                onClick={() => { alert('Leva para User Space') }}
                            >{t('see_now')}
                            </Button>
                            <Button w={{ base: '145px', lg: '200px', '2xl': '300px' }}
                                h={{ base: '50px', '2xl': '60px' }}
                                bg='#FFFFFF'
                                borderRadius='45px'
                                fontFamily='Montserrat'
                                fontStyle='normal'
                                fontWeight='500'
                                fontSize={{ base: '18px', md: '22px' }}
                                lineHeight={{ base: '21px', md: '26px' }}
                                color='#454545'
                                border='2px solid #DFDFDF'
                                _hover={{
                                    bg: '#FFF',
                                    border: '2px solid #7E0000',
                                    color: '#7E0000'
                                }}
                                _focus={{ bg: '#fff' }}
                                onClick={onClose2}
                            >{t('see_later')}
                            </Button>
                        </Flex>
                            <Link w=' 100%'  href='https://api.whatsapp.com/send?phone=5511996202678&app=facebook&entry_point=page_cta' isExternal>
                                <Center fontFamily='Roboto'
                                    fontStyle='normal'
                                    fontWeight='400'
                                    fontSize='16px'
                                    lineHeight='19px'
                                    color='#454545' m='5vh 0vh 5vh 0vh' w='100%'>
                                    <Text mr='1vw'>{t('share-with')}</Text>
                                    <Image src={Whatsapp} />
                                </Center>
                            </Link>
                    </Flex>
                </ModalContent>
            </Modal>
            {/*Modal  DETALHES DA NFT*/}
            <Modal closeOnOverlayClick={true}
                isOpen={isOpen3}
                onClose={onClose3}
                isCentered
                size={'964px'}            >
                {overlay}
                <ModalOverlay />
                <ModalContent w={{ base: '350px', md: '740px', xl: '964px' }}
                    mt={{ base: '40vw', md: '10vh' }}
                >
                    <Center p={{ base: '10px', md: '20px', '2xl': '50px' }}>
                        <Box w='100%'>
                            <IconButton
                                icon={<IoIosArrowBack color='#45454' />}
                                aria-label={""}
                                bg='0'
                                _hover={{ bg: '0' }}
                                _focus={{ bg: '0' }}
                                onClick={onClose3}
                            />
                            <Center w='100%'
                            >
                                <Text fontFamily='Montserrat'
                                    fontStyle='normal'
                                    fontWeight='700'
                                    fontSize={{ base: '16px', md: '18', xl: '22px' }}
                                    lineHeight='24px'
                                    color='#7E0000'
                                >{t('nft_details')}
                                </Text>
                            </Center>
                            <Box display={"flex"} alignItems="center" textAlign="left" w='100%'>
                                {<IoMdList size={'23px'} color='#7E0000' />}
                                <Text ml='12px'
                                    fontFamily="Montserrat"
                                    fontStyle="normal"
                                    fontWeight="700"
                                    fontSize={{ base: '18px', md: "20px" }}
                                    lineHeight="24px"
                                    color="#454545"
                                >{t('description')}
                                </Text>
                            </Box>
                            <Box fontFamily="Montserrat"
                                fontStyle="normal"
                                fontWeight="400"
                                fontSize={{ base: '16px', md: "18px", '2xl': "16px" }}
                                lineHeight={{ base: '18px', md: "20px", '2xl': "20px" }}
                                color="#454545" w='100%' maxW='814px' mt='24px' experimental_spaceY='20px'>
                                <Text
                                >{t('description1')}
                                </Text>
                                <Text
                                >{t('description2')}
                                </Text>
                            </Box>
                            <Box mt={{ base: '24px', md: '24px', lg: '24px', xl: '15px', '2xl': '34px' }} w='164px' >
                                <Text w='100%'
                                    textAlign="left"
                                    fontFamily="Montserrat"
                                    fontStyle="normal"
                                    fontWeight="700"
                                    fontSize={{ base: '14px', md: "16px" }}
                                    lineHeight="20px"
                                    color="#7E0000"
                                    bg='0'
                                    _hover={{ bg: '0' }}
                                    _focus={{ bg: '0' }}
                                >{t('see_more')}
                                </Text>
                            </Box>
                            <Center w='100%' mt={{ base: '15px', md: '25px', lg: '25px', '2xl': '35px' }} >
                                <Box display={"flex"} alignItems="center" textAlign="left" w='100%' >
                                    {<BsStar size={'23px'} color='#7E0000' />}
                                    <Text ml='12px'
                                        fontFamily="Montserrat"
                                        fontStyle="normal"
                                        fontWeight="700"
                                        fontSize={{ base: '18px', md: "20px" }}
                                        lineHeight="24px"
                                        color="#454545"
                                    >{t('benefit_title')}
                                    </Text>
                                </Box>
                            </Center>
                            <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} mt='30px' gap={4}>
                                <BenefitGift  titleBenefit={t('benefit1')} descriptionBenefit={t('benefit_01')} image={comunity} />
                                <BenefitGift  titleBenefit={t('benefit2')} descriptionBenefit={t('benefit_02')} image={diamond} />
                                <BenefitGift titleBenefit={t('benefit3')} descriptionBenefit={t('benefit_03')} image={discont} />
                                <BenefitGift titleBenefit={t('benefit4')} descriptionBenefit={t('benefit_04')} image={ticket} />
                            </Grid>
                        </Box>
                    </Center>
                </ModalContent>
            </Modal >
        </>
    );
}