import { useState, useEffect } from "react";
import { Box, Text } from "@chakra-ui/react";

import { BsStar } from "react-icons/bs";
import {t} from '../../language'

import discont from "../../Assets/Icons/desconto.png";
import diamond from "../../Assets/Icons/diamond.png";
import ticket from "../../Assets/Icons/ticket.png";
import padlock from "../../Assets/Icons/padlock.png";
import { BenefitBuy } from "../Benefit/benefitBuy";

const Benefits = () => {

    const [visivel, setVisivel] = useState<"Information" | "Properties" | "Benefits" | "Actions">("Benefits");

    useEffect(() => {
        //@ts-ignore
        setVisivel(sessionStorage.getItem("visivel") ?? "Benefits");
    }, []);

    useEffect(() => {
        sessionStorage.setItem("visivel", visivel);
    }, [visivel]);

    return (
        <Box h="100%"
        >
            <Box mt={{ base: "5px", md: "10px", lg: "15px", xl: "60px" }}
                border="1px solid #DFDFDF"
                bg='#FFFFFF'
                borderRadius="10px"
                p="25px"
            >
                <Box display={"flex"} alignItems="center" textAlign="left">
                    {<BsStar color='#7E0000' />}
                    <Text ml='8px'
                        fontFamily="Montserrat"
                        fontStyle="normal"
                        fontWeight="700"
                        fontSize="20px"
                        lineHeight="23px"
                        color="#7E0000"
                    >{t('benefits')}

                    </Text>
                </Box>
                <Box  mt={{ base: "5px", md: "10px", lg: "25px", xl: "50px" }} experimental_spaceY='20px'>
                <BenefitBuy titleBenefit={t('benefit1')} descriptionBenefit= {t('benefit_01')}image={diamond} image2={padlock}/>
                <BenefitBuy titleBenefit={t('benefit2')} descriptionBenefit={t('benefit_02')} image={ticket} image2={padlock}/>
                <BenefitBuy titleBenefit={t('benefit3')} descriptionBenefit={t('benefit_03')} image={discont} image2={padlock}/>
                </Box>                
            </Box>
        </Box>
    );
};
export default Benefits;
