import goTokensProd from '../storeConfigurations/gotokens-config-prod.json';
import goTokensTest from '../storeConfigurations/gotokens-config-test.json';

export function useGoTokenConfig() {

    if(process.env.NEXT_PUBLIC_NODE_ENV === "production"){
        return goTokensProd;
    } else {
        return goTokensTest;
    }
    
}