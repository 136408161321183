import React from 'react'
import { Box, Flex, Link, Tooltip, } from '@chakra-ui/react'

import { BsWhatsapp } from 'react-icons/bs';


export const WhatsappHelmet = ({ ...rest }) => {

    return (
        <Flex top={{ base: '90%', md: '90%', lg: '80%', xl: '90%' }}
        mr={{ base: '0', md: '0', lg: '0', xl: '35px' }}
            w='100%'
            zIndex="31"
            position='fixed'
            justifyContent='flex-end'
        >
            <Box mr={{ base: '5px', md: '15px', lg: '10px', xl: '15px' }}
                bg='#FFFFFF'
                borderRadius='45px'
                boxShadow='0px 6px 23px 0px rgba(0, 0, 0, 0.15)'
                _hover={
                    {
                        transform: "translateY(-4px)",
                    }
                }
                transition="all 500ms"
                width="max-content"
            >
                <Tooltip label='Fale Conosco' fontSize='md' hasArrow bg='red.500'>
                    <Link href='https://api.whatsapp.com/send?phone=5511996202678&app=facebook&entry_point=page_cta' isExternal>
                        <BsWhatsapp size={65}
                            color={'#1fb141'}
                        />
                    </Link>
                </Tooltip>
            </Box>

        </Flex>
    )
}
