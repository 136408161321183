import React, { useState, useEffect } from "react";
import {
    Box,
    Flex,
    Text,
    Image,
    Link as ChakaraLink,
} from "@chakra-ui/react";

import { EtherscanGrayscaleIcon, BoxIcon, ViewIcon } from "../../Assets/Icons";

import {t} from "../../language"
import GrList from "../../Assets/Icons/GrList.png";
import NftDetails from "../AccordionComponents/detailsAccordion.";


type NftLinksProps = {
    text: string;
    url: string;
    icon: JSX.Element;
};

const NftLinks = ({ text, url, icon }: NftLinksProps) => (
    <Flex
        alignItems="center"
        mt="15px"
        _first={{
            marginTop: "0px",
        }}
    >
        <Text
            fontFamily="Montserrat"
            fontStyle="normal"
            fontWeight="700"
            fontSize="18px"
            lineHeight="21px"
            color="#A19D9D"
            mr="20px"
        >
            {text}
        </Text>
        <ChakaraLink
            isExternal={true}
            href={url}
            display="flex"
            alignItems="center"
        >
            {icon}
        </ChakaraLink>
    </Flex>
);

const Information = () => {

    const [visivel, setVisivel] = useState<"Information" | "Properties" | "Benefits" | "Actions">("Benefits");

    useEffect(() => {
        //@ts-ignore
        setVisivel(sessionStorage.getItem("visivel") ?? "Benefits");
    }, []);

    useEffect(() => {
        sessionStorage.setItem("visivel", visivel);
    }, [visivel]);

    return (
        <Box 
            // mb={{ base: "40px", md: "50px", lg: "75px", xl: "250px" }}
            h="100%"
        >
            <Box mt={{ base: "5px", md: "10px", lg: "25px", '2xl': "60px" }}
                border="1px solid #DFDFDF"
                bg='#FFFFFF'
                borderRadius="10px"
                p="25px">
                <Flex>
                    <Image src={GrList} color='#7E0000' />
                    <Text fontFamily="Montserrat"
                        fontWeight="700"
                        fontSize="20px"
                        lineHeight="23px"
                        textTransform={"uppercase"}
                        ml="8px"
                        color="#7E0000"
                    >{t('description')}
                    </Text>
                </Flex>
                <>
                    <Text
                        mt="20px"
                        fontFamily="Montserrat"
                        fontStyle="normal"
                        fontWeight="400"
                        fontSize="20px"
                        lineHeight="23px"
                        color="#454545"
                        
                    >{t('description_text')}
                    </Text>
                    <Text mt='30px'
                        fontFamily="Montserrat"
                        fontWeight="700"
                        fontSize="20px"
                        lineHeight="23px"
                        ml="8px"
                        color="#7E0000"
                    >{t('show_more')}
                    </Text>
                </>
            </Box>
            <NftDetails />
            <Box mt="60px" borderBottom="1px solid #DFDFDF" />
            <Box mt="20px" mb="20px">
                <NftLinks
                    text='Ver no Blockchain Explorer'
                    // url={`${blockchainInfo.EXPLORER}/token/${config.contractAddress}?a=${dataNft?.tokenId}`}
                    url={'#'}
                    icon={<EtherscanGrayscaleIcon boxSize={4} />}
                />
                <NftLinks
                    text='Ver MetaData'
                    // url={`https://goblockchain.mypinata.cloud/ipfs/${dataNft?.baseURI}/${dataNft?.tokenId}`}
                    url={'#'}
                    icon={<BoxIcon boxSize={4} />}
                />
                <NftLinks
                    text='Ver no IPFS'
                    // url={`https://goblockchain.mypinata.cloud/ipfs/${dataNft?.imageCID}`}
                    url={'#'}
                    icon={<ViewIcon boxSize={4} />}
                />
            </Box>
        </Box>
    );
};

export default Information;