import React, { createContext, useContext } from 'react'

type Config = {
  apiProvider: boolean
  socialLogin: boolean
  title: string
  networkType: 'mainnet' | 'testnet'
  blockchain: 'ethereum' | 'polygon'
  currency: "BRL" | "USD"
  mainColor: string
  secondaryColor: string
  website_url: string
  linkedin: string
  facebook_url: string
  instagram_url: string
  youtube_url: string
  telegram_url: string
  whatsapp_url: string
}

interface IConfigContext {
  config: Config
}

type ConfigProviderProps = {
  children: React.ReactNode
  config: Config
}

const ConfigContext = createContext<IConfigContext>({} as IConfigContext)

export const ConfigProvider = ({ children, config }: ConfigProviderProps) => {
  return (
    <ConfigContext.Provider value={{ config }}>
      {children}
    </ConfigContext.Provider>
  )
}

export const useConfig = () => {
  const context = useContext(ConfigContext)
  if (context === undefined) {
    throw new Error('useConfig must be used within a ConfigProvider')
  }
  return context
}