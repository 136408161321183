import { Box, Grid, } from "@chakra-ui/react";
import { t } from "../../language"
import { NFTCard } from "../NftCard/CardNFT";
import metadataCID from "../../Assets/Images/Store/imageCID.png";
import imageCID from "../../Assets/Images/Store/NFTBuddha.jpg";


export function Store() {
  const DATA1 = [
    {
      id: '2',
      name: t('nft_name1'),
      image: imageCID,
      coin: 2.7,
      amount: 57
    },
  ];
  const DATA2 = [
    {
      id: '1',
      name: t('nft_name2'),
      image: metadataCID,
      coin: 3.2,
      amount: 87
    },
  ];

  return (
    <Box  >
      <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)' }} gap='2vh'>
        {/* @ts-ignore */}
        <NFTCard data={DATA1} />
        {/* @ts-ignore */}
        <NFTCard data={DATA2} />
        {/* @ts-ignore */}
        <NFTCard data={DATA1} />

        {/* @ts-ignore */}
        <NFTCard data={DATA2} />
        {/* @ts-ignore */}
        <NFTCard data={DATA1} />
        {/* @ts-ignore */}
        <NFTCard data={DATA2} />
      </Grid>
    </Box>
  );
}
