import { useEffect, useState } from "react";
// import Lottie from 'react-lottie';

import {
  Box,
  Flex,
  Heading,
  Text,
  Image,
  IconButton,
  Center,
  Button,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Radio,
  RadioGroup,
  Stack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Link,
} from "@chakra-ui/react";

import { IoIosArrowBack } from "react-icons/io";
import { MdRefresh } from "react-icons/md";
import { BsShareFill } from "react-icons/bs";
import { t } from "../../language"

import NFTBuddha from "../../Assets/Images/Store/imageCID.png";
import Cupon from '../../Assets/Icons/cupon.svg';
import Process from '../../Assets/Images/ModalPagamento/processing.png';
import Process2 from '../../Assets/Images/ModalPagamento/processing2.png';
// import animationData from '../../Assets/Images/ModalPagamento/load.json'
import Info from '../../Assets/Icons/info.svg';
import User from '../../Assets/Icons/user.svg';

import Actions from "../../Components/InformationNFTPurschaseArea/actions";
import Benefits from "../../Components/InformationNFTPurschaseArea/benefits";
import Information from "../../Components/InformationNFTPurschaseArea/information";
import Properties from "../../Components/InformationNFTPurschaseArea/properties";
import { AmoutBox, BuyButtonPurschaseArea } from "../../Components/BuyButtons/buyButtonPurschaseArea";
import Footer from "../../Layouts/Footer";
import { NavPayment } from "../../Layouts/NavPayment";
import React from "react";
import { useConfig } from "../../Hooks/ConfigProvider";
type ButtonsDescriptionProps = {
  text: string;
  onClick?: () => void;
  bg?: string;
  textColor?: string;
  as?: any;
}

const ButtonsDescription = ({ text, bg, textColor, onClick, as }: ButtonsDescriptionProps) => {

  return (
    <Button
      justifyContent={{ base: 'center', md: 'space-between' }}
      fontFamily='Montserrat'
      fontStyle='normal'
      fontWeight='400'
      fontSize={{ base: '17px', '2xl': '20px' }}
      as={as}
      bg={bg}
      lineHeight='24px'
      textColor={textColor}
      onClick={onClick}
      _hover={{ bg: 'transparent', color: '#454545' }}
    >
      {text}
    </Button>
  );
}

const NFT = () => {
  const { config } = useConfig();
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [visivel, setVisivel] = useState<"Information" | "Properties" | "Benefits" | "Actions">("Benefits");
  const [payment, setPayment] = useState<"Buy" | "Loading" | "Checkout">('Buy');
  // const defaultOptions = {
  //   loop: true,
  //   autoplay: true,
  //   animationData: animationData,
  //   rendererSettings: {
  //     preserveAspectRatio: "xMidYMid slice"
  //   }
  // };


  console.log(payment, "AQUI")
  useEffect(() => {
    //@ts-ignore
    setVisivel(sessionStorage.getItem("visivel") ?? "Benefits");
  }, []);


  function handleClick() {
    window.open("/", "_self");
  }
  function teste() {
    // alert('Função info ok')
  }
  function teste1() {
    // alert('Função para min ok')
  }
  function teste2() {
    // alert('Função De presente ok')
  }

  const DeleayComponent = () => {
    const [show, setShow] = React.useState<"Processando" | "Transferindo" | "Concluido">('Processando')
    React.useEffect(() => {
      const timeout = setTimeout(() => {
        setShow('Transferindo')
      }, 3000)

      return () => clearTimeout(timeout)

    }, [show])
    return( show === 'Processando' ? (
      <>
        <ModalHeader p="0"  >
          <ModalCloseButton onClick={() => setPayment('Buy')} />
          <Box >
            <Image src={Process} />
          </Box>
        </ModalHeader>
        <ModalBody p="0" >
          <Center mt=' 109px'>
            <Box h='137px' w='137px' bg='gray.200'></Box>
            {/* <Image src={Process} /> */}
          </Center>
          <Center mt='121px'
            fontFamily='Montserrat'
            fontStyle='normal'
            fontWeight='700'
            fontSize={{ base: '16px', md: '18px', xl: '18px' }}
            lineHeight='22px' color='#A19D9D'>Analisando...</Center>
          <Center mt='34px' mb='59px'
            fontFamily='Montserrat'
            fontStyle='normal'
            fontWeight='400'
            fontSize={{ base: '16px', md: '18px', xl: '18px' }}
            lineHeight='22px' color='#454545'>Estamos processando sua transação.</Center>
        </ModalBody>
      </>

    ) : (
      <>
        <ModalHeader p="0"  >
          <ModalCloseButton onClick={() => setPayment('Buy')} />
          <Box >
            <Image src={Process2} />
          </Box>
        </ModalHeader>
        <ModalBody p="0" >
          <Center mt=' 109px'>
            <Box h='137px' w='137px' bg='gray.200'></Box>
            {/* <Image src={Process} /> */}
          </Center>
          <Center mt='121px'
            fontFamily='Montserrat'
            fontStyle='normal'
            fontWeight='700'
            fontSize={{ base: '16px', md: '18px', xl: '18px' }}
            lineHeight='22px' color='#A19D9D'>Transferindo...</Center>
          <Center mt='34px' mb='59px'
            fontFamily='Montserrat'
            fontStyle='normal'
            fontWeight='400'
            fontSize={{ base: '16px', md: '18px', xl: '18px' }}
            lineHeight='22px' color='#454545'>Estamos processando sua transação.</Center>
        </ModalBody>
      </>
    ))
  }
  return (

    <>
      {/* MODAL DE PAGAMENTO*/}
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent p={{ xl: "20px", '2xl': '30px' }}
          maxW={{
            xl: `${payment === 'Buy' ? ('774px') : ('407px')}`,
            '2xl': `${payment === 'Buy' ? ('924px') : ('507px')}`
          }}
          maxH={{ xl: "500px", '2xl': '721px' }}
          bg='#FFFFFF' overflow='auto'
          boxShadow='0px 6px 23px rgba(0, 0, 0, 0.15)'
          borderRadius='15px'>

          {payment === 'Buy' && (
            <>
              <ModalHeader p='0px' >
                <Flex justifyContent='space-between'>
                  <Text color="#454545"
                    fontFamily='Montserrat'
                    fontStyle='normal'
                    fontWeight='700'
                    fontSize={{ base: '16px', md: '18px', '2xl': '18px' }}
                    lineHeight='22px'>Opções de compra</Text>
                  <ModalCloseButton m='20px' />
                </Flex>
              </ModalHeader>
              <ModalBody p='0px' fontWeight='700'
                fontSize={{ base: '16px', md: '16px', '2xl': '16px' }}
                lineHeight='20px'
                fontFamily='Montserrat'
                fontStyle='normal'
                color="#454545"
              >
                <Flex
                  w='100%' p='21px 60px 21px 30px'>
                  <Box w='33%'>
                    Venda oficial
                  </Box>
                  <Box w='33%'>
                    Preço
                  </Box>
                </Flex>
                <Flex w='100%' p='21px 60px 21px 30px'
                  borderRadius='15px' bg='#FFFFFF'
                  boxShadow='0px 6px 23px rgba(0, 0, 0, 0.15)'>
                  <Flex gap='16px' w='33%'>
                    <Image h='33px' w='33px' src={User} />
                    Buddha Spa
                  </Flex>
                  <Box color="#454545" w='33%'>
                    <Flex>
                      0.0 BUDDHAs
                      <Box ml='45px' color="#848484">
                        R$ 0
                      </Box>
                    </Flex>
                  </Box>
                  <Flex onClick={() => setPayment('Checkout')}
                    cursor="pointer"
                    color={config.mainColor} justifyContent='end' w='33%'>
                    Selecionar
                  </Flex>
                </Flex>
                <Flex w='100%' p='21px 60px 21px 30px' borderRadius='10px'>
                  <Box w='33%'>
                    Revendido por
                  </Box>
                  <Box w='33%'>
                    <Box>
                      Preço
                    </Box>
                  </Box>
                </Flex>

                <Flex w='100%' p='21px 60px 21px 30px' borderRadius='10px' bg='#F8F8F8' boxShadow='0px 6px 23px rgba(0, 0, 0, 0.15'>
                  <Flex gap='16px' w='33%'>
                    <Image h='33px' w='33px' src={User} />
                    Nome do usuario
                  </Flex>
                  <Flex w='33%'>
                    0.0 BUDDHAs
                    <Box ml='45px' color="#848484">
                      R$ 0
                    </Box>
                  </Flex>
                  <Flex color={config.mainColor} justifyContent='end' w='33%'>
                    Selecionar
                  </Flex>
                </Flex>

                <Flex w='100%' p='21px 60px 21px 30px' borderRadius='10px'>
                  <Flex gap='16px' w='33%'>
                    <Image h='33px' w='33px' src={User} />
                    Nome do usuario
                  </Flex>
                  <Flex w='33%'>
                    0.0 BUDDHAs
                    <Box ml='45px' color="#848484">
                      R$ 0
                    </Box>
                  </Flex>
                  <Flex color={config.mainColor} justifyContent='end' w='33%'>
                    Selecionar
                  </Flex>
                </Flex>

                <Flex w='100%' p='21px 60px 21px 30px' borderRadius='10px' bg='#F8F8F8' boxShadow='0px 6px 23px rgba(0, 0, 0, 0.15'>
                  <Flex gap='16px' w='33%'>
                    <Image h='33px' w='33px' src={User} />
                    Nome do usuario
                  </Flex>
                  <Flex w='33%'>
                    0.0 BUDDHAs
                    <Box ml='45px' color="#848484">
                      R$ 0
                    </Box>
                  </Flex>
                  <Flex color={config.mainColor} justifyContent='end' w='33%'>
                    Selecionar
                  </Flex>
                </Flex>

                <Flex w='100%' p='21px 60px 21px 30px' borderRadius='10px'>
                  <Flex gap='16px' w='33%'>
                    <Image h='33px' w='33px' src={User} />
                    Nome do usuario
                  </Flex>
                  <Flex w='33%'>
                    0.0 BUDDHAs
                    <Box ml='45px' color="#848484">
                      R$ 0
                    </Box>
                  </Flex>
                  <Flex color={config.mainColor} justifyContent='end' w='33%'>
                    Selecionar
                  </Flex>
                </Flex>

                <Flex w='100%' p='21px 60px 21px 30px' borderRadius='10px' bg='#F8F8F8' boxShadow='0px 6px 23px rgba(0, 0, 0, 0.15'>
                  <Flex gap='16px' w='33%'>
                    <Image h='33px' w='33px' src={User} />
                    Nome do usuario
                  </Flex>
                  <Flex w='33%'>
                    0.0 BUDDHAs
                    <Box ml='45px' color="#848484">
                      R$ 0
                    </Box>
                  </Flex>
                  <Flex color={config.mainColor} justifyContent='end' w='33%'>
                    Selecionar
                  </Flex>
                </Flex>

                <Flex w='100%' p='21px 60px 21px 30px' borderRadius='10px'>
                  <Flex gap='16px' w='33%'>
                    <Image h='33px' w='33px' src={User} />
                    Nome do usuario
                  </Flex>
                  <Flex w='33%'>
                    0.0 BUDDHAs
                    <Box ml='45px' color="#848484">
                      R$ 0
                    </Box>
                  </Flex>
                  <Flex color={config.mainColor} justifyContent='end' w='33%'>
                    Selecionar
                  </Flex>
                </Flex>

                <Flex w='100%' p='21px 60px 21px 30px' borderRadius='10px' bg='#F8F8F8' boxShadow='0px 6px 23px rgba(0, 0, 0, 0.15'>
                  <Flex gap='16px' w='33%'>
                    <Image h='33px' w='33px' src={User} />
                    Nome do usuario
                  </Flex>
                  <Flex w='33%'>
                    0.0 BUDDHAs
                    <Box ml='45px' color="#848484">
                      R$ 0
                    </Box>
                  </Flex>
                  <Flex color={config.mainColor} justifyContent='end' w='33%'>
                    Selecionar
                  </Flex>
                </Flex>
              </ModalBody>
            </>
          )}
          {payment === 'Checkout' && (
            <>
              <ModalHeader p="0"  >
                <Box >
                  <Text color="#454545"
                    fontFamily='Montserrat'
                    fontStyle='normal'
                    fontWeight='700'
                    fontSize={{ base: '16px', md: '18px', '2xl': '22px' }}
                    lineHeight='24px'>Checkout</Text>
                  <ModalCloseButton m='20px' onClick={() => setPayment('Buy')} />
                </Box>
              </ModalHeader>
              <ModalBody p="0" >
                <Box>
                  <Text
                    fontFamily='Roboto'
                    fontWeight="700"
                    fontSize="16px"
                    color="#454545"
                    lineHeight='19px'
                    // textAlign="left"
                    mt={{ xl: "15px", '2xl': '30px' }}
                  >
                    Método de Pagamento
                  </Text>
                  <Center justifyContent='start' gap='10px' mt='7px' p={{ xl: '12px 20px', '2xl': '18px 25px' }}
                    rounded='10px' bg='#FFFFFF'
                    border='1px solid #DFDFDF'
                  >
                    <Image h='26px' src={User} />
                    <Text> BUDDHA</Text>

                  </Center>
                  <Flex mt={{ xl: "27px", '2xl': '37px' }} fontFamily='Roboto'
                    fontWeight="700"
                    fontSize="20px"
                    color="#454545"
                    lineHeight='23px'
                    borderRadius='10px'
                    justifyContent='space-between' bg="#EAEAEA"
                    p={{ xl: '12px 20px', '2xl': '18px 25px' }}>
                    <Flex gap='16px' >
                      Saldo
                    </Flex>
                    <Box fontWeight="400"  >
                      0.0 BUDDHAs
                    </Box>
                  </Flex>
                  <Box mt={{ xl: "65px", '2xl': '115px' }} height="1px" background="#DFDFDF" />

                  <Flex mt={{ xl: "15px", '2xl': '25px' }}
                    justifyContent="space-between"
                    fontSize="18px"
                    fontWeight="700"
                    lineHeight="21px"
                    fontFamily="Roboto"
                    color="#454545">
                    <Text>
                      Valor Total
                    </Text>
                    <Text>
                      0.000 BUDDHAs
                    </Text>
                  </Flex>
                  <Flex mt={{ xl: "28px", '2xl': '48px' }} >
                    <Radio size='lg'
                      checked={false}
                    //  onChange={() => setTermsIsChecked(!termsIsChecked)}
                    >
                      <Box ml="10px">
                        <Text
                          fontWeight="500"
                          fontFamily="Roboto"
                          fontSize="16px"
                          color="#9E9E9E"
                          flexWrap="wrap"
                          textAlign="left"
                        >
                          Marque o círculo se você concorda com os<br></br>
                          <Link
                            fontWeight="700"
                            fontSize="16px"
                            color="#454545"
                            cursor="pointer"
                            onClick={() =>
                              window.open(
                                "#",
                                "_blank"
                              )
                            }
                          >
                            Termos de Serviço
                          </Link>
                        </Text>
                      </Box>
                    </Radio>

                  </Flex>
                  <Center mt={{ xl: "27px", '2xl': '47px' }}>
                    <Button
                      disabled={false}
                      // onClick={choosePaymentType}
                      borderRadius="45px"
                      bg="#7E0000"
                      _hover={{ bg: "#7E0000" }}
                      _active={{ bg: "#7E0000" }}
                      _focus={{ bg: "#7E0000" }}
                      m="0 auto"
                      p='16px 38px'
                      h={{ xl: "50px", '2xl': '60px' }}
                      w='100%'
                      textAlign="center"
                      onClick={() => setPayment('Loading')}
                      color="#FFFFFF"
                      fontWeight="400"
                      fontSize="22px"
                    >
                      Concluir pagamento
                    </Button>
                  </Center>
                </Box>
              </ModalBody>
            </>
          )}
          {payment === 'Loading' && (
            <DeleayComponent />
          )}

        </ModalContent>
      </Modal>
      <NavPayment />
      <Center w="100%" h='100%'
        bg='#F1F5F9'
      >
        <Flex flexWrap="wrap" gap='10vw'
          flexDirection={{ base: "column", md: "column", xl: "row" }}
          marginTop={{ base: "35px", md: "55px", lg: "67px", '2xl': "97px" }} >
          <Box flexDir="column" >
            <Flex flexDir={{ base: 'column', xl: 'row' }} >
              <Box >
                <IconButton
                  w="36px" ml={{ base: "0px", md: "-80px" }}
                  aria-label="Back button"
                  icon={<IoIosArrowBack />}
                  onClick={handleClick}
                  bg="#fff"
                  border="solid 1px #DFDFDF"
                  _hover={{ bg: "#DFDFDF" }}
                  borderRadius="full"
                />
              </Box>
              <Box
                border='15px solid #FFF'
                overflow="hidden"
                mt={{ base: "20px", md: "20px", lg: "0px" }}
                borderRadius="15px"
                alignItems="center"
                w={{ base: "360px", md: '617px', xl: "427px", '2xl': "617px" }}
              >
                <Image
                  w={{ base: "360px", md: '617px', xl: "427px", '2xl': "617px" }}
                  h={{ base: "360px", md: '617px', xl: "427px", '2xl': "617px" }}
                  src={NFTBuddha} />
              </Box>
            </Flex>
            <Flex justifyContent='start' flexDir={{ base: "column", md: "row" }} w='100%'
              mt={{ base: "20px", md: "20px", lg: "25px", '2xl': "65px" }}>
              <Box>
                <ButtonsDescription
                  onClick={() => setVisivel("Information")}
                  bg={visivel === "Information" ? 'transparent' : "transparent"}
                  textColor={visivel === "Information" ? `${config.mainColor}` : '#848484'}
                  as={visivel === "Information" ? "u" : ""}
                  text="Informações" />
                <ButtonsDescription
                  onClick={() => setVisivel("Properties")}
                  bg={visivel === "Properties" ? "transparent" : "transparent"}
                  textColor={visivel === "Properties" ? `${config.mainColor}` : '#848484'}
                  as={visivel === "Properties" ? "u" : ""}
                  text="Habilidades" />
              </Box>
              <Box>
                <ButtonsDescription
                  onClick={() => setVisivel("Benefits")}
                  bg={visivel === "Benefits" ? "transparent" : "transparent"}
                  textColor={visivel === "Benefits" ? `${config.mainColor}` : '#848484'}
                  text="Benefícios"
                  as={visivel === "Benefits" ? "u" : ""}
                />
                <ButtonsDescription
                  onClick={() => setVisivel("Actions")}
                  bg={visivel === "Actions" ? 'transparent' : "transparent"}
                  textColor={visivel === "Actions" ? `${config.mainColor}` : '#848484'}
                  as={visivel === "Actions" ? "u" : ""}
                  text="Ações" />
              </Box>
            </Flex>
            <Box maxW={{ base: '360px', md: '617px', xl: '427px', '2xl': '616px' }} mb={{ md: '100px', '2xl': '186px' }}>
              {visivel === "Information" && <Information />}
              {visivel === "Properties" && <Properties />}
              {visivel === "Benefits" && <Benefits />}
              {visivel === "Actions" && <Actions />}
            </Box>
          </Box>
          <Box flex="1"
            // position={{ base: "static", "xl": "fixed" }}
            mb={{ base: "40px", md: "1px", xl: "0px" }}
            h="100%"
          >
            <Flex flexDirection='column'
              flexWrap="wrap" mb={{ base: "40px", md: "100px", lg: "190px" }}>
              <Flex flexWrap="wrap" justifyContent={{ base: 'center', xl: 'flex-start' }}>
                <Heading
                  mt="25px"
                  mb={{ base: "5px", md: "15px", xl: "15px" }}
                  fontWeight="700"
                  fontSize={{ base: "28px", md: "32px", xl: "32px" }}
                  fontFamily="Montserrat"
                  lineHeight="38px"
                  color={config.mainColor}
                >
                  {t('buddha_name2')}

                </Heading>
              </Flex>

              <Flex flexWrap="wrap" justifyContent={{ base: 'center', xl: 'flex-start' }}>
                <Text
                  mr="3px"
                  textAlign="center"
                  fontFamily="Montserrat"
                  fontWeight="700"
                  fontSize={{ base: "14px", md: "16px", xl: "16px" }}
                  lineHeight="19px"
                  color="#A19D9D"
                  casing="uppercase"
                >
                  {t('created')}
                </Text>
                <Text
                  textAlign="center"
                  fontFamily="Montserrat"
                  fontWeight="700"
                  fontSize={{ base: "14px", md: "16px", xl: "16px" }}
                  lineHeight="19px"
                  color="#A19D9D"
                  casing="uppercase"
                >
                  {t('date')}
                </Text>
              </Flex>
              <Center gap='10px' w='100%' mt="40px"
                mb="35px"
              >
                <Box>
                  <BuyButtonPurschaseArea
                    title="A partir de 0.0 BUDDHAs" text='R$0,00'
                  />
                </Box>
                <Box>
                  <AmoutBox title="ESTOQUE" text="100/100" />
                </Box>
              </Center>

              <Center mb='30px'>
                <Button
                  w={{ base: '340px', md: '409px' }}
                  h='60px'
                  borderRadius='45px'
                  bg={config.mainColor}
                  _hover={{ bg: `${config.mainColor}` }}
                  _active={{ bg: `${config.mainColor}` }}
                  color="#FFFFFF"
                  fontFamily='Montserrat'
                  fontStyle='normal'
                  fontWeight='700'
                  fontSize={{ base: '16px', md: '18px', xl: '18px' }}
                  lineHeight='21px'
                  onClick={onOpen}>
                  {t('Buy')}
                </Button>

              </Center>
              <>


              </>

              <Center justifyContent={{ base: 'center', xl: 'flex-start' }}
                color="#454545"
                fontFamily='Montserrat'
                fontStyle='normal'
                fontWeight='400'
                fontSize={{ base: '16px', md: '16px', xl: '16px' }}
                lineHeight='21px' mb="35px" >
                <RadioGroup defaultValue='2'>
                  <Stack spacing={5} direction='row'>
                    <Radio bg='#BDBDBD ' onClick={() => teste1()} colorScheme='red' value='2'>
                      {t('for_me')}
                    </Radio>
                    <Radio bg='#BDBDBD' onClick={() => teste2()} colorScheme='red' value='1'>
                      {t('for_gift')}
                    </Radio>
                  </Stack>
                </RadioGroup>
                <Box ml='12px' onClick={() => teste()} >
                  <Image mr='13px'
                    src={Info} />
                </Box>
              </Center>
              <Center>
                <Accordion defaultIndex={[0]} allowMultiple>
                  <AccordionItem w={{ base: '340px', md: '409px' }} bg="#fff" rounded='15px' boxShadow='0px 6px 23px rgba(0, 0, 0, 0.15)'>
                    <AccordionButton p='24px 35px'
                      rounded='15px'
                      _hover={{ bg: '#FFFFFF' }}
                      fontFamily='Montserrat'
                      fontStyle='normal'
                      fontWeight='400'
                      fontSize={{ base: '16px', md: '16px', xl: '16px' }}
                      lineHeight='20px' flex='1' textAlign='left'>
                      <Image mr='13px'
                        src={Cupon} />
                      <Text mr='100px' color="#454545">
                        {t('add_cupom')}
                      </Text>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                      <Flex justifyContent='space-around' mb='2px'>
                        <Input justifyContent='flex-start' rounded='10px' w={{ base: '160px', md: '219px' }} border='1px solid #DFDFDF' placeholder='' />
                        <Button p='11px 27px' bg='#FFF' rounded='45px' border='2px solid #7E0000'
                          fontFamily='Montserrat'
                          fontStyle='normal'
                          fontWeight='400'
                          fontSize={{ base: '16px', md: '16px', xl: '16px' }}
                          lineHeight='20px'>
                          {t('to_apply')}
                        </Button>
                      </Flex>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Center>


              <Center mt='37px'>
                <Button w={{ base: '170px', md: '204px' }}
                  bg='#DDE3E9'
                  _hover={{ bg: '#DDE3E9' }}
                  border='1px solid #DFDFDF'
                  leftIcon={<MdRefresh size={21} />}
                  borderRadius=" 47px 0 0 47px"
                  fontFamily='Montserrat'
                  fontStyle='normal'
                  fontWeight='700'
                  fontSize='16px'
                  lineHeight='19px'
                  color='#454545'
                >
                  {t('refresh')}
                </Button>
                <Button w={{ base: '170px', md: '204px' }}
                  bg='#DDE3E9'
                  _hover={{ bg: '#DDE3E9' }}
                  border='1px solid #DFDFDF'
                  leftIcon={<BsShareFill size={20} />}
                  borderRadius=" 0px 47px 47px 0"
                  fontFamily='Montserrat'
                  fontStyle='normal'
                  fontWeight='700'
                  fontSize='16px'
                  lineHeight='19px'
                  color='#454545'
                >
                  {t('share')}
                </Button>
              </Center>
            </Flex>
          </Box >
        </Flex>
      </Center >
      <Footer />
    </>
  );
};


export default NFT;
