import React from "react";

import { Image, Text, Center } from "@chakra-ui/react";
import {t} from '../../language'
import img1 from "../../Assets/Icons/qrCodeImage.png";
import img2 from "../../Assets/Icons/padlock.png";

export const ButtonResaleYourNFT = () => {

    return (
        <Center justifyContent='space-around'
            p={{ base: "5px", '2xl': "40px 20px 40px 20px"}}
            borderRadius="10px"
            bg='#ffff'
            border="2px solid #DFDFDF"
            // mt={{ base: "10px", md: "0px" }}
            _hover={{ borderColor: '#7e0000' }}
            w={{ base: '210px',md: "100%", xl:'100%', '2xl': "617px" }}
            h={{ base: '60px', '2xl': "70px" }}
        >
            <Image src={img1} h="25px" w="25px"
                
            />
            <Text
                fontFamily='Montserrat'
                fontSize={{ base: '12px', md: '14px', lg: '14px', '2xl': '18px' }}
                fontWeight='700'
                color='#A4A4A4'
                lineHeight='106%'
                textDecoration='none'
                textAlign='center'
            >{t('resale')}
            </Text>
            <Image
                mr={{ base: '5px', md: 0 }}
                src={img2} h="25px" w="25px" />
        </Center>
    );
};
