import React from "react";
import { Link, Image, Text, Center } from "@chakra-ui/react";

type ImageTextLinkButtonProps = {
  image?: string;
  text?: string;
  url?: string;
  tooltip?: string;

};
export const LinkToSeeYourNFT = ({ image, url, tooltip, text }: ImageTextLinkButtonProps) => {

  return (
    <Link isExternal={true}
      href={url}
      _focus={{ outline: 0 }}
    >
      <Center justifyContent='space-around'
       p={{ base: "5px", '2xl': "20px" }}
        borderRadius="10px"
        bg='#ffff'
        border="2px solid #DFDFDF"
        mt={{ base: "10px", md: "0px" }}
        _hover={{ borderColor: '#FFFF'}}
        w={{ base: '210px',md: "303px", xl:'210px', '2xl': "303px" }}
        h={{ base:  '60px', '2xl': "70px" }}
      >
        <Image src={image} h="30px" w="30px"  />
        <Text ml={{ base: "10px", '2xl': "25px" }}
          fontFamily='Montserrat'
          fontSize={{ base: '16px', md: '18px', lg: '14px', '2xl': '18px' }}
          fontWeight='700'
          color='#454545'
          textDecoration='none'
        >
          {text}
        </Text>
      </Center>
    </Link>

  );
};
