import React from "react";
import {
    Text,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
} from "@chakra-ui/react";


import { MinusIcon, AddIcon } from "@chakra-ui/icons";

type BoxDropdawnProps = {
    title: string;
    content: string;
};

export const BoxDropdawnHome = ({ title, content, }: BoxDropdawnProps) => {

    return (
        <Accordion
            allowToggle
            maxW="813px"
            border="none"
            mt={{ base: "12px", md: "38px" }}
            w={{ base: "360px", sm: "450px", md: "730px", lg: "813px" }}
        >
            <AccordionItem
                border="none"
                borderRadius="15px"
                boxShadow="0px 6px 23px rgba(0, 0, 0, 0.1)"
                overflow="hidden"
            >
                {({ isExpanded }) => (
                    <>
                        <Text bg="#FFFFFF">
                            <AccordionButton h="75px" _focus={{ outline: 0 }}>
                                <Text
                                    ml={{ base: "8px", md: "47px" }}
                                    flex="1"
                                    textAlign="left"
                                    fontSize={{ base: "18px", md: "22px" }}
                                    fontWeight="600"
                                    lineHeight="27px"
                                >
                                    {title}
                                </Text>
                                {isExpanded ? (
                                    <MinusIcon color='#7e0000' fontSize="22px" mr="27px" ml="10px" />
                                ) : (
                                    <AddIcon color='#7e0000' fontSize="22px" mr="27px" ml="10px" />
                                )}
                            </AccordionButton>
                        </Text>
                        <AccordionPanel pb={4} p="20px">
                            <Text flex="1"
                                textAlign="left"
                                fontSize={{ base: "18px", md: "20px" }}
                                fontWeight="600"
                                lineHeight="27px"
                                p="0 25px 25px 47px"
                                dangerouslySetInnerHTML={{ __html: content }}
                            />
                        </AccordionPanel>
                    </>
                )}
            </AccordionItem>
        </Accordion>
    );
};
