import { Center, Text, Box } from "@chakra-ui/react";

import { RiFacebookFill } from "react-icons/ri";
import { FiTwitter } from "react-icons/fi";
import { BsLink45Deg } from "react-icons/bs";
import { IoLogoWhatsapp, IoIosMail } from "react-icons/io";
import { ImLinkedin2 } from "react-icons/im";
import { IconLinkShareHome } from "./iconLinkShareHome";
import { t } from "../../language";


function copyText() {
    navigator.clipboard.writeText
        ("https://nftshop.stockproseries.com.br/");
}

type IconProps = {
    mt?: string;
};

export const IconLinkShare = ({ mt }: IconProps) => {

    const SOCIALSHOME = [
        {
            name: 'Copiar link',
            icon: <BsLink45Deg color="#454545" size={"25px"} onClick={() => copyText()} />,

        },
        {
            name: "Facebook",
            icon: <RiFacebookFill color="#454545" size={"20px"} />,
            url: "https://www.facebook.com/buddhaspa",
        },
        {
            name: "WhatsApp",
            icon: <IoLogoWhatsapp color="#454545" size={"20px"} />,
            url: "https://api.whatsapp.com/send?phone=5511996202678&app=facebook&entry_point=page_cta",
        },
        {
            name: "Twitter",
            icon: <FiTwitter color="#454545" size={"20px"} />,
            url: "#",
        },
        {
            name: "Linkedin",
            icon: <ImLinkedin2 color="#454545" size={"20px"} />,
            url: "https://www.linkedin.com/company/buddhaspa/",
        },
        {
            name: "e-mail",
            icon: <IoIosMail color="#454545" className="Icon-socialMT" size={"25px"} />,
            url: "#",
        },
    ];

    return (
        <Box mt={mt}>
            <Text fontFamily='Montserrat'
                fontWeight='400'
                textAlign='center'
                fontSize='18px'
                lineHeight='21px'
                color='#454545'
            >{t('share_with1')}
            </Text>
            <Box mt='19px'
                px={{ base: "22px", md: "52px", xl: "32px", "2xl": "33px" }}
                h='60px'
                bg='#FFFFFF'
                borderRadius='35px'
                boxShadow={`0px 4px 19px rgba(0, 0, 0, 0.15)`}
            >
                <Center maxW='220px'>
                    {SOCIALSHOME.map((social, index) => (
                        <IconLinkShareHome
                            key={index}
                            url={social.url}
                            icon={social.icon}
                            name={social.name}
                        />
                    ))}
                </Center>
            </Box>
        </Box>

    );
}