import React from "react";
import { Center, Flex, useDisclosure } from "@chakra-ui/react";
import { ModalNavConnect } from "../../Components/ModalNavConnect/ComponentsModalNavConnect/modalNavConnect";
import {t} from "../../language"

export const NavPayment = ({ ...rest }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Flex w='100%'
      top="-2px"
        h={{ base: "50px", md: "60px" }}
        backdropFilter="blur(60px)"
        background=' rgba(0, 0, 0, 0.6)'
        boxShadow='0px 4px 19px rgba(0, 0, 0, 0.15)'
        position="fixed"
        zIndex="40"
        {...rest}
      >
      </Flex>
      {/* <Image
        ml="-45px"
        src={iconBR}
        bg='#454545'
        zIndex="45"
        _hover={{
          transform: "translateY(-4px)",
        }}
        _focus={{ outline: 0 }}
        h="45px"
      /> */}
      <ModalNavConnect isopen={isOpen} onopen={onOpen} onclose={onClose} />
      <Center
      background=' rgba(0, 0, 0, 0.3)'
        h={{ base: "50px", md: "60px" }}
        w={{ base: '180px', md: '257px' }}
        fontStyle="normal"
        color="#FFFFFF"
        fontWeight="700"
        fontSize={{ base: '16px', md: '18px' }}
        lineHeight="21px"
        fontFamily="Montserrat"
        backdropFilter="blur(120px)"
        position="fixed"
        right="0px"
        onClick={onOpen}
        top="-2px"
        zIndex="40"
        textTransform='uppercase'
        cursor="pointer"
      >
        {t("Connect_Wallet")}
      </Center>
    </>
  );
};
