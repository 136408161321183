import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    UnorderedList,
    PopoverArrow,
    Button,
    Text,
    Flex,
    Image,
} from "@chakra-ui/react";

import { IoIosArrowDown } from 'react-icons/io';

import globo from '../../Assets/Icons/globo.png'
import plantCoin from '../../Assets/Icons/plantCoin.png'
import clover from '../../Assets/Icons/clover.png'
import socialAction from '../../Assets/Icons/socialAction.png'
import diamond from '../../Assets/Icons/diamond.png'
import group from '../../Assets/Icons/group.png'
import acess from '../../Assets/Icons/acess.png'
import discont from '../../Assets/Icons/discont.png'
import box from '../../Assets/Icons/box.png'
import mett from '../../Assets/Icons/mett.png'

type buttonOptimizationProps = {
    text: string
}

export function ButtonOpmizatioStore({ text }: buttonOptimizationProps) {
    return (
        <Flex w='100%' h='45px' borderRadius='9px' bg='#FFFFFF'>
            <Button w='100%'
                justifyContent='start'
                fontFamily='montserrat'
                fontStyle='normal'
                fontWeight='400'
                fontSize='16px'
                lineHeight='21px'
                mt='12px'
                bg='none'
                _hover={{ bg: '#EFEFEF' }}
                _focus={{ bg: '#EFEFEF' }}
            >
                {text}
            </Button>
        </Flex>
    );
}

type ButtonOpmizatioStoreBenefitsProps = {
    text: string
    icon: any
}

export function ButtonOpmizatioStoreBenefits({ text, icon }: ButtonOpmizatioStoreBenefitsProps) {
    return (
        <Button
            w='100%'
            // borderRadius={{ base: brBase, md: br }}
            justifyContent='start'
            fontFamily='montserrat'
            fontStyle='normal'
            fontWeight='400'
            fontSize='16px'
            lineHeight='21px'
            mt='12px'
             bg='none'
            _hover={{ bg: '#EFEFEF' }}
            _focus={{ bg: '#EFEFEF' }}
        >
            <Image src={icon} />
            <Text ml='14px'>{text}</Text>
        </Button>
    );
}

type popoverhomeAllTypesProps = {
    textHeader: string
    br: string
    brBase: string
    listItem: string
    listItem1: string
    listItem2: string
    listItem3: string
    listItem4: string
    listItem5: string
    listItem6: string
    listItem7: string
}

export function ListPopoverHomeAllTypes({
    textHeader,
    br,
    brBase,
    listItem,
    listItem1,
    listItem2,
    listItem3,
    listItem4,
    listItem5,
    listItem6,
    listItem7
}: popoverhomeAllTypesProps) {
    return (
        <Popover        
            placement='bottom'
            closeOnBlur={false}
        >
            <PopoverTrigger>
                <Button
                    w='100%' h='60px'
                    p='28px 36px '
                    borderRadius={{ base: brBase, md: br }}
                    color='#454545'
                    _hover={{ bg: '#DDE3E9' }}
                    rightIcon={<IoIosArrowDown />}
                    fontFamily='montserrat'
                    fontStyle='normal'
                    fontWeight='400'
                    fontSize='17px'
                    lineHeight='21px'
                >
                    {textHeader}
                </Button>
            </PopoverTrigger>
            <PopoverContent color='#454545' borderColor='#7E0000' rounded='15px'>
                <PopoverArrow bg='#7E0000' />
                {/* <PopoverCloseButton bg='yellow' /> */}
                <PopoverBody w='100%' h='100%' >
                    <UnorderedList textDecoration='none'
                        bg='#FFFFFF'
                        mb='15px'
                    >

                        <ButtonOpmizatioStore text={listItem} />
                        <ButtonOpmizatioStore text={listItem1} />
                        <ButtonOpmizatioStore text={listItem2} />
                        <ButtonOpmizatioStore text={listItem3} />
                        <ButtonOpmizatioStore text={listItem4} />
                        <ButtonOpmizatioStore text={listItem5} />
                        <ButtonOpmizatioStore text={listItem6} />
                        <ButtonOpmizatioStore text={listItem7} />
                    </UnorderedList>
                </PopoverBody>
            </PopoverContent>
        </Popover >
    );
}

type popoverhomeRecentsProps = {
    textHeader: string
    br: string
    brBase: string
    listItem: string
    listItem1: string
    listItem2: string
    listItem3: string
    listItem4: string
}

export function ListPopoverHomeRecents({ textHeader, br, brBase, listItem, listItem1, listItem2, listItem3,listItem4 }: popoverhomeRecentsProps) {
    return (
        <Popover
            placement='bottom'
            closeOnBlur={false}
        >
            <PopoverTrigger>
                <Button
                    w='100%' h='60px'
                    borderRadius={{ base: brBase, md: br }}
                    color='#454545'
                    _hover={{ bg: '#DDE3E9' }}
                    rightIcon={<IoIosArrowDown />}
                    fontFamily='montserrat'
                    fontStyle='normal'
                    fontWeight='400'
                    fontSize='17px'
                    lineHeight='21px'
                >
                    {textHeader}
                </Button>
            </PopoverTrigger>
            <PopoverContent color='#454545' borderColor='#7E0000' rounded='15px' >
                <PopoverArrow bg='#7E0000' />
                {/* <PopoverCloseButton bg='yellow' /> */}
                <PopoverBody w='100%' h='100%' >
                    <UnorderedList textDecoration='none'
                        bg='#FFFFFF'
                        borderRadius='9px'
                        fontFamily='montserrat'
                        fontStyle='normal'
                        fontWeight='400'
                        fontSize='17px'
                        lineHeight='21px'
                        mb='15px'
                    >
                        <ButtonOpmizatioStore text={listItem} />
                        <ButtonOpmizatioStore text={listItem1} />
                        <ButtonOpmizatioStore text={listItem2} />
                        <ButtonOpmizatioStore text={listItem3} />
                        <ButtonOpmizatioStore text={listItem4} />
                    </UnorderedList>
                </PopoverBody>
            </PopoverContent>
        </Popover >
    );
}


type popoverhomeBenefitsProps = {
    textHeader: string
    br: string
    brBase: string
    listItem: string
    listItem1: string
    listItem2: string
    listItem3: string
    listItem4: string
    listItem5: string
    listItem6: string
    listItem7: string
    listItem8: string
    listItem9: string
}

export function ListPopoverHomeBenefits({ textHeader,
    br,
    brBase,
    listItem,
    listItem1,
    listItem2,
    listItem3,
    listItem4,
    listItem5,
    listItem6,
    listItem7,
    listItem8,
    listItem9
}: popoverhomeBenefitsProps) {
    return (
        <Popover placement='bottom' closeOnBlur={false}  >
            <PopoverTrigger>
                <Button
                    w='100%' h='60px'
                    p='28px 36px 28px 0px'
                    borderRadius={{ base: brBase, md: br }}
                    color='#454545'
                    _hover={{ bg: '#DDE3E9' }}
                    rightIcon={<IoIosArrowDown />}
                    fontFamily='montserrat'
                    fontStyle='normal'
                    fontWeight='400'
                    fontSize='17px'
                    lineHeight='21px'
                >
                    {textHeader}
                </Button>
            </PopoverTrigger>
            <PopoverContent borderColor='#7E0000' rounded='15px'>
                <PopoverArrow bg='#7E0000' />
                <PopoverBody justifyContent='center' w='100%' h='100%'   >
                    <UnorderedList textDecoration='none'
                        mb='35px'
                    >
                        <ButtonOpmizatioStoreBenefits icon={globo} text={listItem} />
                        <ButtonOpmizatioStoreBenefits icon={plantCoin} text={listItem1} />
                        <ButtonOpmizatioStoreBenefits icon={clover} text={listItem2} />
                        <ButtonOpmizatioStoreBenefits icon={socialAction} text={listItem3} />
                        <ButtonOpmizatioStoreBenefits icon={diamond} text={listItem4} />
                        <ButtonOpmizatioStoreBenefits icon={group} text={listItem5} />
                        <ButtonOpmizatioStoreBenefits icon={acess} text={listItem6} />
                        <ButtonOpmizatioStoreBenefits icon={discont} text={listItem7} />
                        <ButtonOpmizatioStoreBenefits icon={box} text={listItem8} />
                        <ButtonOpmizatioStoreBenefits icon={mett} text={listItem9} />
                    </UnorderedList>
                </PopoverBody>
            </PopoverContent>
        </Popover >
    );
}

