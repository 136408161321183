import React from "react";

import { Link, Image,Text, Center } from "@chakra-ui/react";
import {t} from '../../language'
import img1 from "../../Assets/Icons/gift.svg";
import img2 from "../../Assets/Icons/padlock.png";

export const ButtonGift= () => {
   
    return (
        <Link isExternal={true}
            href={'#'}
            _focus={{ outline: 0 }}
        >
            <Center justifyContent='space-around'
             p={{ base: "5px", '2xl': "20px" }}
                borderRadius="10px"
                bg='#ffff'
                border="2px solid #DFDFDF"
                mt={{ base: "10px", md: "0px" }}
                _hover={{ borderColor: '#7e0000' }}
                w={{ base: '210px',md: "303px", xl:'210px', '2xl': "303px" }}
                h={{ base:  '60px', '2xl': "70px" }}
                
            >
                <Image src={img1} h="25px" w="25px"/>

                <Text 
                    fontFamily='Montserrat'
                    fontSize={{ base: '12px', md: '14px', lg: '14px', '2xl': '18px' }}
                    fontWeight='700'
                    color='#A4A4A4'
                    lineHeight='106%'
                    textDecoration='none'
                >{t('gift2')}
                </Text>

                <Image 
                    src={img2} h="25px" w="25px"/>
            </Center>
        </Link>

    );
};
