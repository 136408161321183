import {
    Flex,
    Text,
    Center,
    Image,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    List,
    ListItem,
} from "@chakra-ui/react";

import { AiOutlineStar } from "react-icons/ai";



type AccordionProps = {
    color: string;
    benefits: { title: string; icon: string }[];
    wall?: string;
};

export const NftBenefits = ({ color, benefits, wall }: AccordionProps) => {
    return (
        <Accordion allowToggle defaultIndex={[0]}>
            <AccordionItem border="none">
                {({ isExpanded }) => (
                    <>
                        <h2>
                            <AccordionButton bg='#f8f8f8'
                                justifyContent="space-between"
                                py={3}
                                _focus={{
                                    outline: "none",
                                }}
                            >
                                {isExpanded ? (
                                    <Center fontSize={16}>Esconder Benefícios</Center>
                                ) : (
                                    <Center fontSize={16}>Ver Benefícios</Center>
                                )}
                                {isExpanded ? (
                                    <Flex h='17px'>
                                        <AiOutlineStar fontSize='16px' />
                                        <Text ml='5px' mt='-4px'>3</Text>
                                    </Flex>
                                ) : (
                                    <Flex h='17px'>
                                        <AiOutlineStar fontSize='16px' />
                                        <Text ml='5px' mt='-4px'>3</Text>
                                    </Flex>
                                )}
                                {/* <AccordionIcon ml={5} color={color} /> */}
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <List spacing={4} ml={4}>
                                {benefits.map((benefit, index) => (
                                    <ListItem key={index}  fontSize={16} lineHeight="18px" >
                                    <Center justifyContent="space-between"
                                    >
                                        <Image mr="1vw"  src={benefit.icon} />
                                            
                                            <Text
                                                fontWeight="400"
                                                fontSize="14px"
                                                lineHeight="16px"
                                                flexWrap="wrap"
                                                maxW="280px"
                                            >
                                                {benefit.title}
                                            </Text>
                                        </Center>
                                    </ListItem>
                                ))}
                            </List>
                        </AccordionPanel>
                    </>
                )}
            </AccordionItem>
        </Accordion>
    );
};
