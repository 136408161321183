import {
    Box,
    Flex,
    Text,
    Center,
} from "@chakra-ui/react";


import descont from "../../Assets/Icons/descont.png";
import comunity from "../../Assets/Icons/comunity.png";
import { t } from "../../language"

import { BuyNowButton } from "../BuyButtons/buyNowButtonIntoNFT";
import { NftBenefits } from "./NFTBenefits";



type NFTProps = {
    baseTop?: string;
    mdTop?: string;
    lgTop?: string;
    data: { id: number; name: string; image: string; coin: string; amount: number }[];
};
export const NFTCard = ({ baseTop, mdTop, lgTop, data, }: NFTProps) => {
    const BENEFITS = [
        {
            icon: descont,
            title: t('benefit_01'),
        },
        {
            icon: comunity,
            title: t('benefit_02'),
        },
        {
            icon: comunity,
            title: t('benefit_03'),
        },
    ];    
    return (
        <Center w='100%' mt={{ base: baseTop, md: mdTop, lg: lgTop }}>
            <Box background="#fff"
                transition="all 500ms"
                width="max-content"
                borderRadius="15px"
            >
                <Box
                    position="relative"
                    borderRadius="15px"
                    boxShadow="0px 4px 19px rgba(0, 0, 0, 0.15)"
                    overflow="hidden"
                    w={{ base: "330px", xl: "327px", '2xl': "377px" }}
                > 
                {data.map((data, index) => (
                    <Box key={index}
                        position="relative"
                        borderRadius="15px"
                        boxShadow="0px 4px 19px rgba(0, 0, 0, 0.15)"
                        overflow="hidden"
                        w={{ base: "330px", xl: "327px", '2xl': "377px" }}
                    >                        
                        <Box bgImage={data.image}
                            bgPosition="center"
                            bgRepeat="no-repeat"
                            position="relative"
                            cursor="pointer"
                            backgroundSize="contain"
                            h={{ base: "330px", xl: "327px", '2xl': "377px" }}
                        >
                            <BuyNowButton text='Comprar agora' />
                            <Center
                                backdropFilter="blur(20px)"
                                h={{ base: "42px", '2xl': "52px" }}
                                position="absolute"
                                width=" 100%"
                                bottom="0"
                                background=' rgba(0, 0, 0, 0.6)'
                                boxShadow='0px 4px 19px rgba(0, 0, 0, 0.15)'
                                justifyContent='space-between'
                            >
                                <Flex>                                    
                                    <Text
                                        fontWeight="700"
                                        fontSize={{ base: "16px", '2xl': "18px" }}
                                        lineHeight="21px"
                                        color="white"
                                        ml='2vw'
                                    > {t('coin')} {data.coin}  BUDDHAs
                                    </Text>
                                    <Text
                                        fontWeight="700"
                                        fontSize={{ base: "16px", '2xl': "18px" }}
                                        lineHeight="21px"
                                        color="white"
                                        ml='1vw'
                                    > {data.amount}/100
                                    </Text>
                                </Flex>
                                {/* <Flex>
                                    <Text
                                        fontWeight="700"
                                        fontSize="18px"
                                        lineHeight="21px"
                                        color="white"
                                        mr='5px'
                                    >
                                        <CountUp start={0} duration={5} end={100} delay={0}>
                                            {({ countUpRef }) => (
                                                <div>
                                                    <span ref={countUpRef} />
                                                </div>
                                            )}
                                        </CountUp>
                                    </Text>
                                    <Text
                                        fontWeight="700"
                                        fontSize="18px"
                                        lineHeight="21px"
                                        color="white"
                                        mr='30px'
                                    >
                                        /100
                                    </Text>
                                </Flex> */}
                            </Center>
                        </Box>
                        <Box borderTop="1px solid #DFDFDF"   >
                            <Center flexDir="row" mt="20px" justifyContent="space-between">
                                <Box>
                                    <Text ml="18px"
                                        fontStyle="normal"
                                        fontSize="18px"
                                        fontWeight="700"
                                        lineHeight="21px"
                                        fontFamily="Montserrat"
                                        mb="4px"
                                        color=" #454545"
                                    >
                                     {data.name}
                                    </Text>
                                </Box>
                            </Center>
                        </Box>
                        <NftBenefits
                            // color={'#7e0000'} 
                            color='#FFFFFF'
                            benefits={BENEFITS} />
                    </Box>
                    ))}
                </Box>
            </Box>
        </Center>
    );
}