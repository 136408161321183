import React from "react";
import { Box, Center, } from "@chakra-ui/react";

import openseaimg from "../../Assets/Icons/opensea.png";

import { LinkToSeeYourNFT } from "../ButtonsActionsNFTPurschaseArea/linkToSeeYourNFT";
import { ButtonTransferYourNFT } from "../ButtonsActionsNFTPurschaseArea/transferNFT";
import { ButtonSeeNFTbyQRCode } from "../ButtonsActionsNFTPurschaseArea/seeYourNFTwhitQRCode";
import { ButtonResaleYourNFT } from "../ButtonsActionsNFTPurschaseArea/reSale";
import { ButtonGift } from "../ButtonsActionsNFTPurschaseArea/gift";

const Actions = () => {

    return (
        <Box>
            <Center justifyContent="space-between"
                mt={{ base: "12px", md: "18px", lg: "25px", '2xl': "60px" }}
                flexWrap="wrap"
                flexDir={{ base: "column", md: 'row' }} >
                <LinkToSeeYourNFT
                    text='Veja no OpenSea'
                    image={openseaimg}
                    url='#'
                />
            <ButtonGift/>
            </Center>
            <Center w='100%' >
                <Center justifyContent="space-between" mt="20px" flexWrap="wrap" flexDir={{ base: "column", md: 'row' }} maxW='617px' w='100%'>
                    <ButtonTransferYourNFT />
                    <ButtonSeeNFTbyQRCode />
                </Center>
            </Center>
            <Center mt="20px" >
                <ButtonResaleYourNFT />
            </Center>
        </Box>
    );
};

export default Actions;