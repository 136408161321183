import React from "react";
import { Box, Text, Link, Flex } from "@chakra-ui/react";

import { VscSearch } from "react-icons/vsc";
import { t } from "../../language";

export default function NftDetails() {
  return (
    <Box border="1px solid #DFDFDF" borderRadius="10px" p="25px" mt='30px' bg='#FFFFFF'>
      <Box display={"flex"} alignItems="center" flex="1" textAlign="left">
        {<VscSearch  color="#7E0000" />}
        <Text ml='8px'
          fontFamily="Montserrat"
          fontStyle="normal"
          fontWeight="700"
          fontSize="20px"
          lineHeight="23px"
          color="#7E0000"
        >{t('details')}
        </Text>
      </Box>
      <Flex justifyContent='space-between' mt='39px'>
        <Text
          fontFamily='Montserrat'
          fontStyle='normal'
          fontWeight='400'
          fontSize='18px'
          lineHeight='21px'
          color='#454545'
        >{t('contract_address')}
        </Text>
        <Link color='#4E9FD8'
          fontFamily='Montserrat'
          fontStyle='normal'
          fontWeight='700'
          fontSize='18px'
          lineHeight='21px'
        >
          0x7654h...4h3p
        </Link>
      </Flex>
      <Flex justifyContent='space-between' mt='8px'>
        <Text
          fontFamily='Montserrat'
          fontStyle='normal'
          fontWeight='400'
          fontSize='18px'
          lineHeight='21px'
          color='#454545'
        >{t('token_id')}
        </Text>
        <Link color='#454545'
          fontFamily='Montserrat'
          fontStyle='normal'
          fontWeight='700'
          fontSize='18px'
          lineHeight='21px'
        >
          1
        </Link>
      </Flex>
      <Flex justifyContent='space-between' mt='8px'>
        <Text
          fontFamily='Montserrat'
          fontStyle='normal'
          fontWeight='400'
          fontSize='18px'
          lineHeight='21px'
          color='#454545'
        >{t('standard_token')}
        </Text>
        <Link color='#454545'
          fontFamily='Montserrat'
          fontStyle='normal'
          fontWeight='700'
          fontSize='18px'
          lineHeight='21px'
        >
          ERC-1155
        </Link>
      </Flex>
      <Flex justifyContent='space-between' mt='8px'>
        <Text
          fontFamily='Montserrat'
          fontStyle='normal'
          fontWeight='400'
          fontSize='18px'
          lineHeight='21px'
          color='#454545'
        >{t('blockchain')}
          
        </Text>
        <Link color='#454545'
          fontFamily='Montserrat'
          fontStyle='normal'
          fontWeight='700'
          fontSize='18px'
          lineHeight='21px'
        >
          Polygon
        </Link>
      </Flex>
      <Flex justifyContent='space-between' mt='8px'>
        <Text
          fontFamily='Montserrat'
          fontStyle='normal'
          fontWeight='400'
          fontSize='18px'
          lineHeight='21px'
          color='#454545'
        >{t('metadata')}          
        </Text>
        <Link color='#454545'
          fontFamily='Montserrat'
          fontStyle='normal'
          fontWeight='700'
          fontSize='18px'
          lineHeight='21px'
        >
          Centralizada
        </Link>
      </Flex>
    </Box>
  );
}
