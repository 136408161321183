import { Flex, Text, Center } from '@chakra-ui/react'
import React from 'react'
import { t } from '../../language'

function Footer() {
  return (
    <Center width="100%"
      h="72px"
      p="20px"
      as="footer"
      justifyContent="space-between"
      bottom='0'
      backdropFilter="blur(60px)"
      background=' rgba(0, 0, 0, 0.6)'
      boxShadow='0px 4px 19px rgba(0, 0, 0, 0.15)'
      flexDirection={{ base: 'column', md: 'row' }}
    > <Flex lineHeight='26px'>
        <Text mr={{ base: 0, md: "10px" }}
          fontWeight="300"
          fontFamily='Roboto'
          fontSize={{ base: '16px', md: '16px' }}
          color='#FFFFFF'>
         {t('made_by')}
        </Text>
        <Text
          color="#FDC921"
          fontWeight="bold"
          fontSize={{ base: '18px', md: '24px' }}
          ml={{ base: '5px', md: 0 }}
          cursor="pointer"
          onClick={() =>
            window.open("https://goblockchain.io/gotokens/", "_blank")
          }
        >goTokens
        </Text>
      </Flex>
      <Flex fontFamily="Montserrat"
        fontWeight="400"
        fontSize={{ base: '18px', md: '20px' }}
      >
                <Text
          color="#FFFFFF"
          textTransform='uppercase'
          fontFamily='Roboto'
          fontStyle="normal"
          fontWeight="400"
          fontSize={{ base: '18px', md: '18px' }}
          lineHeight="21px"
          ml={{ base: '25px', md: "51px" }}
          cursor="pointer"
          line-height="23px"
          // onClick={() =>
          //   window.open(
          //     "https://drive.google.com/file/d/1D8bSTcXUmKjAj7AgUJWIPccmE6XItKt2/view?usp=sharing",
          //     "_blank"
          //   )
          // }
        >
           {t('contact')}
        </Text>
        <Text
          color="#FFFFFF"
          textTransform='uppercase'
          fontFamily='Roboto'
          fontStyle="normal"
          fontWeight="400"
          fontSize={{ base: '18px', md: '18px' }}
          lineHeight="21px"
          ml={{ base: '25px', md: "51px" }}
          cursor="pointer"
          line-height="23px"
          onClick={() =>
            window.open(
              "https://drive.google.com/file/d/1D8bSTcXUmKjAj7AgUJWIPccmE6XItKt2/view?usp=sharing",
              "_blank"
            )
          }
        >
           {t('privacy_policies')}
        </Text>
        <Text color="#FFFFFF"
        textTransform='uppercase'
          fontFamily='Roboto'
          fontStyle="normal"
          fontWeight="400"
          fontSize={{ base: '18px', md: '18px' }}
          lineHeight="21px"
          ml={{ base: '5px', md: "51px" }}
          mr={{ base: '25px', md: 0 }}
          cursor="pointer"
          onClick={() =>
            window.open(
              "https://drive.google.com/file/d/1D8bSTcXUmKjAj7AgUJWIPccmE6XItKt2/view?usp=sharing",
              "_blank"
            )
          }
        >
          {t('terms_use')}
        </Text>
      </Flex>
    </Center>
  )
}

export default Footer