import React from "react";
import {
    Box,
    Center,
    Text,
    Flex,
    Image,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    Stack,
    InputGroup,
    Input,
    InputRightElement,
} from "@chakra-ui/react";

import { AiOutlineMail } from "react-icons/ai";
import { FiKey } from "react-icons/fi";

import buddhaIconLogin from "../../../Assets/Images/Login/buddhaIconLogin.png";
import florBuddaLogin from "../../../Assets/Images/Login/florBuddaLogin.png";
// import { WalletLoading } from "./modalLoginLoading";
// import { ModalFistLogin } from "./modalFistLOgin";


type Props = {
    isopen: boolean;
    onclose: () => void;
    onopen: () => void;
};

export const ModalNavConnect = ({
    isopen,
    onclose,
    onopen,
    ...rest
}: Props) => {
    return (
        <Modal isOpen={isopen} onClose={onclose} >
            <ModalOverlay
                backdropFilter="blur(60px)"
                background="rgba(255, 255, 255, 0.32)"
            />
            <ModalContent bg="none" boxShadow="none">
                <ModalBody>
                    <Center >
                        <Flex maxW={{ base: '345px', md: '1213px', lg: '1213px' }}
                            flexDir={{ base: "column", md: "column", lg: "row" }}
                            mt={{ base: '10px', md: '15px', lg: '20px', xl: '169px' }}
                        >
                            <Center>
                                <Flex>
                                    <Wallet />
                                    {/* <WalletLoading /> */}
                                    {/* <ModalFistLogin /> */}
                                    <Button ml='30px'
                                        w='45px'
                                        bg="#fff"
                                        borderRadius="full"
                                        onClick={onclose}
                                        color="#454545"
                                        fontSize="28px"
                                        top="10px"
                                        right="10px"
                                        _focus={{
                                            boxShadow: "none",
                                        }}
                                    >
                                        X
                                    </Button>
                                </Flex>
                            </Center>
                        </Flex>
                    </Center>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};


const Wallet = () => (

    <Box ml='55px'
        w={{ base: "345px", md: "550px", xl: "550px" }}
        h="648px"
        bg="#ffff"
        boxShadow="0px 6px 23px rgba(0, 0, 0, 0.15)"
        borderRadius="15px"
    >
        <Center mt="33px">
            <Image src={buddhaIconLogin} />
        </Center>
        <Center>
            <Flex mt="30px" fontFamily="montserrat">
                <Text mr="3px" fontWeight="400" fontSize="18px" lineHeight="21px" color="#454545" >
                    Logue com sua conta
                </Text>
                <Text mr="3px" fontWeight="700" fontSize="18px" lineHeight="21px" color="#7e0000" >
                    Buddha Spa
                </Text>
                <Text fontWeight="400" fontSize="18px" lineHeight="21px" color="#454545">
                    para
                </Text>
            </Flex>
        </Center>
        <Center>
            <Box fontFamily="montserrat">
                <Text mr="3px" fontWeight="400" fontSize="18px" lineHeight="21px" color="#454545" >
                    entrar na plataforma.
                </Text>
            </Box>
        </Center>
        <Center mt="42px">
            <Stack>
                <Text fontFamily='montserrat'
                    fontStyle='normal'
                    fontWeight='700'
                    fontSize='16px'
                    lineHeight='19px'
                    color='#454545'
                >
                    E-mail
                </Text>
                <InputGroup mt='5px'>
                    <Input
                        w={{ base: "320px", md: "407px" }}
                        h="60px"
                        border="1px solid #dfdfdf"
                        fontFamily="Montserrat"
                        fontWeight="400"
                        fontSize="18px"
                        lineHeight="21px"
                    />
                    <InputRightElement
                        mt="10px"
                        children={<AiOutlineMail color="#454545" fontSize="25px" />}
                    />
                </InputGroup>
                <Text mt='25px'
                    fontFamily='montserrat'
                    fontStyle='normal'
                    fontWeight='700'
                    fontSize='16px'
                    lineHeight='19px'
                    color='#454545'
                >
                    Senha
                </Text>
                <InputGroup mt="5px">
                    <Input
                        w={{ base: "320px", md: "407px" }}
                        h="60px"
                        border="1px solid #dfdfdf"
                        color="#C4C4C4"
                        fontFamily="Montserrat"
                        fontWeight="400"
                        fontSize="18px"
                        lineHeight="21px"
                    />
                    <InputRightElement
                        mt="10px"
                        children={
                            <FiKey
                                color="#454545"
                                fontSize="25px"
                                overlinePosition="Center"
                            />
                        }
                    />
                </InputGroup>
            </Stack>
        </Center>
        <Center mt="42px">
            <Button
                w={{ base: "320px", md: "407px" }}
                h="60px"
                bg="#7e0000"
                borderRadius="45px"
                fontFamily="Montserrat"
                fontStyle="normal"
                fontWeight="400"
                fontSize="18px"
                lineHeight="21px"
                textAlign="center"
                color="#ffffff"
                _hover={{ bg: "#7e0000" }}
            >
                <Image src={florBuddaLogin} /> Logar com minha conta Buddha Spa
            </Button>
        </Center>
        <Center mt="33px">
            <Flex fontFamily="Montserrat" >
                <Text mr="3px" fontWeight="700" fontSize="18px" lineHeight="19px" color="#454545" >
                    Não tem conta?
                </Text>
                <Button ml='3px'
                    bg='transparent'
                    w='100px'
                    mt='-10px'
                    fontWeight="700"
                    fontSize="18px"
                    lineHeight="19px"
                    color='#7e0000'
                    _hover={{ bg: "transparent" }}
                >
                    Clique aqui
                </Button>
                <Text ml='5px' fontWeight="400" fontSize="18px" lineHeight="19px" color="#454545">
                    para criar uma conta.
                </Text>
            </Flex>
        </Center>

    </Box>
);


