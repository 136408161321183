import { Box, Center } from "@chakra-ui/react";

import { Nav } from "../../Layouts/Nav";
import Footer from "../../Layouts/Footer";
import ViewHome from "../../Components/HomeComponents/viewHome";
import { HeaderBannerTitleAndSocialLinks } from "../../Layouts/Header";
import { WhatsappHelmet } from "../../Components/WhatsappHelmet";
// import CookiesImg from '../../Assets/Icons/cookies.svg'

export default function Home() {

  return (
    <>
      <Box position="relative" w='100%' bg='#BDBDBD'>
        <Nav />
        <HeaderBannerTitleAndSocialLinks />       
        <Center>
         {/* <Cookies/> */}
          <Box maxWidth="1221px" w='100%'>
            <ViewHome />
          </Box>
          <WhatsappHelmet />
        </Center>
        <Footer />
      </Box>
    </>
  );
}
// function Cookies() {
//   const {
//       isOpen, onOpen,
//       onClose } = useDisclosure()
//   return (
//     <Box position='fixed'>
//       <Modal
//         onClose={onClose}
//         isOpen={true} 
//       >
//         <ModalContent rounded='15px' mt={{xl:'70vh','2xl':'80vh'}}>
//           <ModalBody rounded='15px' w='60vw' maxW='789px' bg='#FFF'>
//             <Flex>
//               <Center mr='40px'>
//                  <Image src={CookiesImg} /> 
//                 </Center>
//               <Box>
//                 <Text
//                   fontFamily='Montserrat'
//                   fontStyle='normal'
//                   fontSize='18px'
//                   fontWeight='700'
//                   lineHeight='64px'
//                   color='#454545'
//                 > {t('cookies_title')}
//                 </Text>
//                 <Text mt='-9px' fontFamily='Montserrat'
//                   fontStyle='normal'
//                   fontSize='16px'
//                   fontWeight='400'
//                   lineHeight='20px'
//                   color='#A19D9D'>{t('cookies_text')} 
//                 </Text>
//                 <Box mt='9px'>
//                 {t('cookies_text1')} 
//                   <Text fontFamily='Montserrat'
//                     fontStyle='normal'
//                     fontSize='16px'
//                     fontWeight='700'
//                     lineHeight='20px' color='#009FE3'>
//                       {t('cookies_text2')}</Text>
//                 </Box>
//               </Box>
//               <Center>
//                 <Box experimental_spaceY=' 20px'>
//                   <Button padding='16px 38px' border='2px solid #DFDFDF'
//                    rounded='45px'
//                    fontFamily='Montserrat'
//                    fontStyle='normal'
//                    fontSize='16px'
//                    fontWeight='400'
//                    lineHeight='20px'
//                     color='#454545'
//                    bg='transparent'
//                    onClick={onClose}>
//                     {t('to_accept')}
//                   </Button>
//                   <Button padding='16px 20px' border='2px solid #DFDFDF'
//                    rounded='45px'
//                    fontFamily='Montserrat'
//                    fontStyle='normal'
//                    fontSize='16px'
//                    fontWeight='400'
//                    lineHeight='20px'
//                     color='#FF5656'
//                    bg='transparent'  onClick={onClose}>
//                     {t('not_accept')}
//                   </Button>
//                 </Box>
//               </Center>
//             </Flex>
//           </ModalBody>
//         </ModalContent>
//       </Modal>
//     </Box>
//   )
// }


