import { Center, Tooltip, Text, Link } from "@chakra-ui/react";


type IconProps = {
    icon: any;
    url?: string;
    text?: string;
    name?: string;
    marginTop?: string;
    onClick?: any;
    border?: string;
};
export const IconLinkToFolowSocialAccounts = ({ icon, url, text, name, marginTop, border, onClick }: IconProps) => {

    return (
        <>
            <Tooltip 
                hasArrow
                label={name}
                shouldWrapChildren
                bg="#454545"
                mt="-8px"
                borderRadius="5px"
                onClick={onClick}
                color='#FFFFFF'
            >
                <Link
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    {...(url && { href: url, target: "_blank" })}
                    {...(!url && {
                        textDecoration: "none",
                        cursor: "initial",
                        _hover: { textDecoration: "none" },
                    })}
                    _focus={{ outline: 0 }}
                >
                    <Center mt='10px'
                        marginTop={marginTop}
                        w="45px"
                        h="45px"
                        className="icon-social"
                        border={border}
                        borderRadius="56px"
                        bg='#FFFFFF'
                        _hover={{ bg: "#ece9e1", color: "#7e0000" }}
                    >
                        {icon}
                    </Center>
                    <Text
                        mt="11px"
                        fontWeight="400"
                        maxW="60px"
                        textAlign="center"
                        fontFamily="Montserrat"
                    >
                        {text}
                    </Text>
                </Link>
            </Tooltip>
        </>
    );
};


