import { Box, Center, Flex, Image, Text } from "@chakra-ui/react"

type BenefitProps = {
    titleBenefit: string,
    descriptionBenefit: string,
    image: any
    image2?: any
}
export const BenefitBuy = ({ titleBenefit, descriptionBenefit, image, image2 }: BenefitProps) => {
    return (
        <Flex p='3vh'
            borderRadius="15px"
            shadow="0px 6px 22px rgba(0, 0, 0, 0.1);"
            cursor="pointer">
            <Center mr='26px' w={{ base: '35px', md: '40px', lg: '40px', '2xl': '50px' }}
                h={{ base: '35px', md: '40px', lg: '40px', '2xl': '50px' }}
                border="1px solid #DFDFDF"
                borderRadius="full"
            >
                <Image src={image} />
            </Center>
            <Box mr={{ base: '26px',md: '276px', xl: '20px', '2xl': '126px' }}
                maxW={{  base: '176px', md: '276px', xl: '216px', '2xl': '276px' }} >
                <Text fontFamily='Roboto'
                    fontStyle='normal'
                    fontWeight='400'
                    fontSize={{ base: '14px', md: '14px', '2xl': '18px' }}
                    lineHeight={{ base: '14px', md: '16px', '2xl': '20px' }}
                    color='#414141'
                >
                    {titleBenefit}

                </Text>
                <Text
                    mt='12px'
                    fontFamily='Roboto'
                    fontStyle='normal'
                    fontWeight='400'
                    fontSize={{ base: '14px', md: '14px', '2xl': '18px' }}
                    lineHeight={{ base: '14px', md: '16px', '2xl': '20px' }}
                    color='#929292'
                >
                    {descriptionBenefit}

                </Text>
            </Box>
            <Center>
                <Image w='19px'
                    h='19px' src={image2} />
            </Center>

        </Flex>
    )

}
