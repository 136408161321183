import * as React from "react"
import { BrowserRouter as Router } from 'react-router-dom'
import { ChakraProvider, theme, Box } from "@chakra-ui/react"

import '../Assets/Styles/app.css'
import Content from '../Routes/content'
import { ConfigProvider } from "../Hooks/ConfigProvider"
import { useGoTokenConfig } from "../Utils/configJson"

export const App = () => {
  const goTokenConfig = useGoTokenConfig();
  return (
    <ChakraProvider theme={theme} >
      {/*@ts-ignore */}
      <ConfigProvider config={goTokenConfig}>
        <Router>
          <Box bg='#F1F5F9' >
            <Content />
          </Box>
        </Router>
      </ConfigProvider>
    </ChakraProvider>
  )


}


