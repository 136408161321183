import React, { useState, useEffect } from "react";
import { Box, Text, Flex, Center, Progress } from "@chakra-ui/react";
import {t} from '../../language'
import { BsThreeDotsVertical } from "react-icons/bs";

type TableSpecificationsProps = {
    text: string;
    bg: string;
    value?: number;
    value1?: number;
}

export const TableSpecifications = ({ text, bg, value, value1 }: TableSpecificationsProps) => {
    return (
        <Box p='25px'
            bg={bg}
        >
            <Center w='100%' flexDir='row' justifyContent='space-between'>
                    <Text
                        fontFamily='montserrat'
                        fontStyle='normal'
                        fontWeight='700'
                        fontSize={{ base: '14px', md: '12px', '2xl': '16px' }}
                        lineHeight={{ base: '16px', md: '18px', '2xl': '20px' }}
                        color='#A19D9D'
                    >
                        {text}
                    </Text>
                 <Progress ml={{ base: '14px', md: '40px', '2xl': '70px' }}
                    w={{ base: '220px', md: '160px', '2xl': '220px' }}value={value} borderRadius='45px' colorScheme='red' />                    
                <Box ml={{ base: '10px', md: '14px', '2xl': '22px' }}>
                {value1}                 
                </Box>
            </Center>
        </Box>
    );

}

const Properties = () => {

    const [visivel, setVisivel] = useState<"Information" | "Properties" | "Benefits" | "Actions">("Benefits");

    useEffect(() => {
        //@ts-ignore
        setVisivel(sessionStorage.getItem("visivel") ?? "Benefits");
    }, []);

    useEffect(() => {
        sessionStorage.setItem("visivel", visivel);
    }, [visivel]);

    return (
        <Box h="100%"
        >
            <Box mt={{ base: "5px", md: "10px", lg: "15px", xl: "60px" }}
                border="1px solid #DFDFDF"
                bg='#FFFFFF'
                borderRadius="10px"
                p="25px"
            >
                <Box display={"flex"} alignItems="center" textAlign="left">
                    {<BsThreeDotsVertical color="#7E0000" />}
                    <Text ml='8px'
                        fontFamily="Montserrat"
                        fontStyle="normal"
                        fontWeight="700"
                        fontSize="20px"
                        lineHeight="23px"
                        color="#7E0000"
                    >{t('properties')}
                    </Text>
                </Box>
                <Box mt='46px'>
                    <Flex justifyContent='space-between'
                        h='15px'
                    >
                        <Text
                            fontFamily='Montserrat'
                            fontStyle='normal'
                            fontWeight='700'
                            fontSize='16px'
                            lineHeight='20px'
                            color='#454545'
                        >{t('properties')}
                        </Text>
                        <Text mr={{ base: '45px', md: '111px' }}
                            fontFamily='Montserrat'
                            fontStyle='normal'
                            fontWeight='700'
                            fontSize='16px'
                            lineHeight='20px'
                            color='#454545'
                        >{t('level')}
                        </Text>
                    </Flex>
                    <Center mt='46px'  >
                        <TableSpecifications text='Nome da Propriedade' value={95}  value1={9} bg='#F8F8F8' />
                    </Center>
                    <Center >
                        <TableSpecifications text='Nome da Propriedade' value={25}  value1={2} bg='#FFFFFF' />
                    </Center>
                    <Center >
                        <TableSpecifications text='Nome da Propriedade' value={45}  value1={4} bg='#F8F8F8' />
                    </Center>
                    <Center >
                        <TableSpecifications text='Nome da Propriedade' value={55}  value1={5} bg='#FFFFFF' />
                    </Center>
                    <Center >
                        <TableSpecifications text='Nome da Propriedade' value={62}  value1={6} bg='#F8F8F8' />
                    </Center>
                    <Center >
                        <TableSpecifications text='Nome da Propriedade' value={82}  value1={8} bg='#FFFFFF' />
                    </Center>
                </Box>
            </Box>
        </Box>
    );
};

export default Properties;