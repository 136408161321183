import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";

type BuyButtonFakeProps = {
    title: string;
    text: string;

}

type AmoutProps = {
    title: string;
    text: string;
}

export const BuyButtonPurschaseArea = ({ title, text }: BuyButtonFakeProps) => {
    return (
        <Flex
            alignItems="center"
            justifyContent="center"
            borderRadius="15px"
            border="1px solid #FFFFFF"
            bg='#FFFFFF'
            h={{ base: '80px', md: '95px' }}
            w={{ base: '200px', md: "282px" }}
            // ml={{ base: "10px", md: "0" }}
            // mr={{ base: "0px", md: "21px" }}
            boxShadow={"0px 6px 23px rgba(0, 0, 0, 0.15)"}
            p="20px 30px"
        >
            <Box>
                <Text  fontFamily="Montserrat"
                    fontStyle="normal"
                    fontWeight="700"
                    fontSize={{ base: "14px", md: "16px" }}
                    lineHeight="20px"
                    mb="7px"
                    color="#7E0000"
                >
                   {title}
                </Text>
                <Flex alignItems="center">
                    <Text fontFamily="Montserrat"
                        fontStyle="normal"
                        fontWeight="700"
                        fontSize={{ base: "14px", md: "16px" }}
                        lineHeight="19px"
                        color='#454545'
                    >
                        {text}
                    </Text>
                </Flex>
            </Box>
        </Flex>

    );
}

export const AmoutBox = ({ title, text }: AmoutProps) => {
    return (
        <Flex alignItems="center"
            justifyContent="center"
            borderRadius="15px"
            border="1px solid #FFFFFF"
            bg='#FFFFFF'
            w={{ base: '100px', md: "120px" }}
            h={{ base: '80px', md: "95px" }}
            // mr={{ base: "70px", md: "210px", lg: "470px", xl: "0" }}
            boxShadow={"0px 6px 23px rgba(0, 0, 0, 0.15)"}
        // p="20px 30px"
        >
            <Box>
                <Text
                    fontFamily="Montserrat"
                    fontStyle="normal"
                    fontWeight="300"
                    fontSize={{ base: "14px", md: "16px" }}
                    lineHeight="19px"
                    mb="7px"
                    color="#454545"
                >
                    {title}
                </Text>
                <Flex alignItems="center">
                    <Text
                        fontFamily="Montserrat"
                        fontStyle="normal"
                        fontWeight="700"
                        fontSize={{ base: "14px", md: "16px" }}
                        lineHeight="19px"
                        color='#454545'
                    >
                        {text} 
                    </Text>
                </Flex>
            </Box>
        </Flex>
    );
}