import React from "react";
import { Center, Button } from "@chakra-ui/react";

type BuyNowButtonProps = {
    text: string;
}
function handleClick() {
    window.open("/nft", "_self");
    }

export const BuyNowButton = ({ text }: BuyNowButtonProps) => {
    return (
        <Center>
            <Button mt='270px'
                w={{ base: "170px", md: "170px" }}
                h={{ base: "50px", md: "50px" }}
                bg='transparent'
                borderRadius='45px'
                fontFamily='montserrat'
                fontStyle='normal'
                fontWeight='400'
                fontSize={{ base: "16px", md: "18px" }}
                color='transparent'
                onClick={handleClick}
                _hover={{
                    bg: "#FFFFFF", color: "#454545",
                    transform: "translateY(-4px)",
                    width: '150px',
                    height: '46px',
                    fontSize: '14px',
                }}
                transition="all 500ms"
            >
                {text}
            </Button>
        </Center>
    );
}