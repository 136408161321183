import React from "react";
import { Box, Button, Center, Flex, Text, Image, } from "@chakra-ui/react";

import fireMostPopular from '../../Assets/Icons/fire.png';

import { Store } from "../Store";
import { IconLinkShare } from "../ButtonsComponents/buttonShareWith";
import { BoxDropdawnHome } from "../DropDawn/dropDown";
import { ListPopoverHomeAllTypes, ListPopoverHomeBenefits, ListPopoverHomeRecents } from "../PopOvers/popOverHome";
import { ButtonRescueNFTHome } from "../ButtonRescueNFT";
import { useConfig } from "../../Hooks/ConfigProvider";
import {t} from '../../language'
import { NFTCard } from "../NftCard/CardNFT";
import metadataCID from "../../Assets/Images/Store/imageCID.png";

const FAQITEMS = t("faqItems")
const DATA = [
  {
    id: '1',
    name: t('nft_name'),
    image: metadataCID,
    coin: 3.2,
    amount: 27
  },
];
export default function ViewHome() {
const {config}= useConfig();
  function handleClick() {
    window.open("/nft", "_self");
  }
  return (
    <Box >
      <Center m={{ base: "20px", lg: "40px" }} mt="82px" h='100%' >
        <Flex mt={{ base: "80px", md: "80px", lg: "80px", xl: "80px" }}
          gap='97px'
          flexDir={{ base: "column", xl: "row" }}
          w='100%'
        >
          <Center ml={{ base: "0px", xl: "100px", '2xl': "200px" }}>
            <Box >
              {/* @ts-ignore */}
              <NFTCard data={DATA} />
            </Box>
          </Center>
          <Center >
            <Box mt={{ base: "20px", md: "45px", lg: "45px", xl: "35px", '2xl': "45px" }} >
              <Flex w='300px' h='30px'  >
                <Image src={fireMostPopular} />
                <Text ml='12px'
                  mt='10px'
                  fontFamily='Montserrat'
                  fontStyle='normal'
                  fontWeight='700'
                  fontSize={{ base: '18px', xl: '20px' }}
                  lineHeight='23px'
                  color='#848484'
                >
                {t('nft_title')}
                </Text>
              </Flex>
              <Text mt='15px'
                fontFamily='Montserrat'
                fontStyle='normal'
                fontWeight='700'
                fontSize={{ base: '18px', xl: '20px' }}
                lineHeight='23px'
                color='#454545'
              >
                {t('nft_about')}
              </Text>
              <Text mt='15px'
                fontFamily='Montserrat'
                fontStyle='normal'
                fontWeight='300'
                fontSize={{ base: '20px', xl: '22px' }}
                lineHeight='26px'
                color='#454545'
                w={{ base: '100%', md: '100%', lg: '500px', xl: '550px' }}
              >{t('nft_description')}
              </Text>
              <Center justifyContent={{ base: 'center', md: 'center', lg: 'center', xl: "flex-start" }} >
                <Button borderRadius='45px'
                  fontFamily='Montserrat'
                  w='217px'
                  h='60px'
                  bg={config.mainColor}
                  mt='45px'
                  color='#FFFFFF'
                  onClick={handleClick}
                   _hover={{ bg: `${config.mainColor}` }}
                >{t('button_details')}
                </Button>
              </Center>
            </Box>
          </Center>
        </Flex>
      </Center>
      <Center m={{ base: "20px", lg: "40px", xl: "20px" }}
        mt={{ base: '162px', md: '162px', lg: '162px', xl: '162px' }}
        h='100%'
      >
        <Flex justifyContent={{ base: 'center', md: 'space-between', lg: 'space-between' }}
          flexWrap='wrap'
          w='100%'
        >
          <Flex justifyContent={{ base: 'center', md: 'space-between', lg: 'space-between' }}
            h='60px'
          >
            <Box>
              <Text fontFamily='Montserrat'
                fontStyle='normal'
                fontWeight='700'
                fontSize='30px'
                lineHeight='37px'
                color='#454545'
                mt='12px'
              >
                {t('store')}
              </Text>
            </Box>
            <Box display={{ base: 'none', md: 'none', lg: 'none', xl: 'block' }}>
              <ButtonRescueNFTHome ml='71px' />
            </Box>
          </Flex>
          <Box >
            <Flex justifyContent={{ base: 'center', md: 'space-between', lg: 'space-between' }}
              mt={{ base: '15px', md: '15px', lg: '15px', xl: 0 }}
              h={{ base: '100%', md: '60px' }}
              borderRadius='47px'
              flexWrap={{ base: 'wrap', md: 'nowrap' }}
              mb='25px'
            >
              <ListPopoverHomeAllTypes

                textHeader={t('all_nft')}
                listItem={t('collection')}
                listItem1={t('member')}
                listItem2={t('golden')}
                listItem3={t('ganesha')}
                listItem4={t('wellness')}
                listItem5={t('lotus')}
                listItem6={t('luck')}
                listItem7={t('namaste')}
                br='47px 0 0 47px'
                brBase="47px"
              />
              <ListPopoverHomeRecents textHeader={t('last')}
                listItem={t('last')}
                listItem1={t('less_recent')}
                listItem2={t('more_relevant')}
                listItem3={t('smaller_price')}
                listItem4={t('lager_price')}
                br='none'
                brBase="47px"
              />
              <ListPopoverHomeBenefits textHeader={t('all_benefits')}
                listItem={t('all_benefits')}
                listItem1={t('investment')}
                listItem2={t('prize_draw')}
                listItem3={t('social_action')}
                listItem4={t('exclusivity')}
                listItem5={t('community')}
                listItem6={t('access')}
                listItem7={t('discount')}
                listItem8={t('augmented_reality')}
                listItem9={t('Meet & Greet')}
                br='0 47px 47px 0'
                brBase="47px"
              />
              <Box ml='71px' w='100%' display={{ base: 'none', md: 'none', lg: 'block', xl: 'none' }} >
                <ButtonRescueNFTHome />
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Center >
      <Center w='100%' mb='25px' >
        <Box display={{ base: 'block', md: 'block', lg: 'none' }}>
          <ButtonRescueNFTHome />
        </Box>
      </Center>
      <Store />
      <Center justifyContent={{ base: 'center', md: 'center', lg: 'center' }}
        mb='25px'
      >
        <Button borderRadius='45px'
          fontFamily='Montserrat'
          fontWeight='400'
          fontSize='22px'
          lineHeight='27px'
          p='16px 38px'
          h='60px'
          bg={config.mainColor}
          mt='173px'
          color='#FFFFFF'
           _hover={{ bg: `${config.mainColor}` }}
        >
         {t('button_more')}
        </Button>
      </Center>
      <Center>
        <Text mt={{ base: '25px', md: '80px', lg: '90px', xl: '120px' }}
          fontFamily='Montserrat'
          fontStyle='normal'
          fontWeight='700'
          fontSize={{ base: '30px', md: '30px', lg: '36px', xl: '36px' }}
          lineHeight='44px'
        >
          {t('common_questions')}
          
        </Text>
      </Center>
      <Center  mt='29px'
        flexDir="column" mb="78px" 
        p="0px 120px">
        {FAQITEMS.map((item: { title: string; content: string; }, index: React.Key | null | undefined) => (
          <BoxDropdawnHome key={index} title={item.title} content={item.content} />
        ))}
      </Center>
      <Center mt={{ base: "50px", md: "79px" }}
        mb={{ base: "70px", md: "100px", xl: "230px" }}
      >
        <IconLinkShare />
      </Center>
    </Box>
  );

}
